<template>
  <div>
    <label v-if="labelRequire" class="form-label"><slot /></label>
    <select
      :name="name"
      id="project-manager__select"
      class="form-select"
      v-model="selected"
      :value="value"
      @change="(event) => selectedOption(event)"
      placeholder="Place"
    >
<option value="">Select</option>
      <option
        v-for="option of options"
        :key="option"
        :value="valueKey ? option[valueKey] : option"
      >
        {{ optionKey ? option[optionKey] : option }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "VsudSelect",
  data() {
    return {
      selected: this.value,
    };
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    labelRequire: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeHolder: {
      type: String,
      default: "Please Select",
    },
    name: {
      type: String,
      require: true,
    },
    value: {
      type: String,
      default: "",
    },
    optionKey: {
      type: String,
      default: "",
    },
    valueKey: {
      type: String,
      default: "",
    },
  },
  methods: {
    selectedOption(e) {
      this.$emit("change-select", e);
    },
  },
};
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@500;700&display=swap');
  * {
    font-family: 'DM Sans';
  }

.form-select:focus {
  border-color: var(--bs-primary);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px var(--bs-primary);
}



.user-project-overview .form-select{
height: 44px;
  border-radius: 4px;
}
</style>
<template>
  <div>
    <div class="d-flex justify-content-between my-3">
      <label class="custom-form-label">{{ $t('activity') }}</label>
      <button
        class="btn btn-primary rounded-circle icon-button"
        :style="{ height: '57px' }"
        @click="addActivity"
      >
        <i class="fa fa-plus" />
      </button>
    </div>
    <ul class="list-group list-group-flush lis">
      <li
        class="list-group-item no-border"
        v-for="activity of newActivity"
        :key="activity.id"
      >
        <div class="row">
          <div class="col-6">
            <input
              type="text"
              class="form-control"
              name="activityName"
              :placeholder="$t('activity_name')"
              :value="activity.activityName"
              @input="(event) => addActivityList({ event, id: activity.id })"
            />
          </div>
          <div class="col-3">
            <input
              type="number"
              min="0"
              max="100"
              class="form-control"
              :value="activity.totalHours"
              @input="(event) => addActivityList({ event, id: activity.id })"
              @keyup="numberChecker"
              :placeholder="$t('hours')"
              name="totalHours"
            />
          </div>
          <div class="input-group-append col-2">
            <button
              class="btn btn-danger rounded-circle icon-button"
              :style="{ height: '57px', transform: 'rotate(45deg)' }"
              @click="deleteActivity({ id: activity.id, _id: activity._id })">
              <i class="fa fa-plus" />
            </button>
          </div>
          <div class="col-9">
            <textarea
              class="form-control"
              name="activityDescription"
              :placeholder="$t('activity_description')"
              :value="activity.activityDescription"
              @input="(event) => addActivityList({ event, id: activity.id })"
            />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
// import VsudSelect from "../../components/VsudSelect.vue";
export default {
  name: "AddActivity",
  data() {
    return {
      // newActivity: [],
      // addedActivity: {
      //   id: 0,
      //   selectedActivity: "",
      //   totalHours: 0,
      // },
    };
  },
  components: {
    // VsudSelect,
  },
  props: {
    // activities: {
    //   type: Array,
    //   default: () => [],
    //   require: true,
    // },
    newActivity: {
      type: Array,
      default: () => [],
    },
    addActivity: {
      type: Function,
    },
    deleteActivity: {
      type: Function,
    },
    addActivityList: {
      type: Function,
    },
  },

  methods:{
    ...mapActions(["deleteActivity",]),
    numberChecker(event){
      var currentVal = event.target.value
      if(currentVal < 0){
        event.target.value = ""
      }
      else {
        var _numStr = currentVal.toString()
        if(_numStr.length > 4){
          _numStr = _numStr.substring(0, _numStr.length-1);
        }
        var parsedValue = parseInt(_numStr)
        event.target.value = parsedValue
      }
    }
  },
  // methods: {
  //   addActivity() {
  //     this.newActivity.push({
  //       ...this.addedActivity,
  //       id: this.addedActivity.id++,
  //     });
  //   },
  //   deleteActivity(id) {
  //     this.newActivity = this.newActivity.filter(
  //       (activity) => activity.id !== id
  //     );
  //   },
  //   addActivityList(e, id) {
  //     const { value, name } = e.target;
  //     this.newActivity[id][name] = value;
  //   },
  // },
};
</script>

<style scoped>

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.no-border {
  border: none !important;
}
button.icon-button {
  border-radius: 100% !important;
  padding: 0;
  position: relative;
  width: 40px !important;
  height: 40px !important;
}
</style>
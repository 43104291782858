<template>
  <div>
    <svg
      class="email-icon"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99997 9.39961C8.32546 9.39961 9.39997 8.32509 9.39997 6.99961C9.39997 5.67413 8.32546 4.59961 6.99997 4.59961C5.67449 4.59961 4.59998 5.67413 4.59998 6.99961C4.59998 8.32509 5.67449 9.39961 6.99997 9.39961Z"
        stroke="#72758A"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.4 4.59963V7.59962C9.4 8.07701 9.58965 8.53485 9.92721 8.87242C10.2648 9.20998 10.7226 9.39962 11.2 9.39962C11.6774 9.39962 12.1352 9.20998 12.4728 8.87242C12.8104 8.53485 13 8.07701 13 7.59962V6.99962C12.9999 5.64544 12.5417 4.33111 11.7 3.27035C10.8582 2.20958 9.68233 1.46476 8.36359 1.15701C7.04484 0.84925 5.66076 0.996653 4.43641 1.57525C3.21206 2.15384 2.21944 3.1296 1.61996 4.34386C1.02048 5.55812 0.849385 6.93947 1.13451 8.26329C1.41963 9.58712 2.14419 10.7756 3.19038 11.6354C4.23657 12.4952 5.54286 12.9758 6.89684 12.9991C8.25083 13.0224 9.57287 12.587 10.648 11.7636"
        stroke="#72758A"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: ["customClass"],
};
</script>

<style lang="css" scoped>
.email-icon {
  /* position: absolute;
  top: 26%;
  transform: translate(-50%, -50%);
  left: 6%; */
}
</style>
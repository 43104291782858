<template>
  <div class="fw-bolder" :v-if="header">{{ header }}</div>
  <div class="col">
    <div class="row">
      <div class="col-2 p-0">
        <hr class="horizontal bg-primary hr-primary" />
      </div>
      <div class="col-10 p-0">
        <hr class="horizontal" style="background-color: #e8edf1" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VsudHorzntl",
  props: {
    header: {
      type: String,
    },
  },
};
</script>

<style scoped>
.hr-primary {
  background-color: #ea0606 !important;
  color: #ea0606 !important;
  border: solid 1.5px var(--bs-primary) !important;
  height: 1.5px !important;
}
</style>
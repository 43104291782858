<template>
  <div class="d-flex justify-content-between px-3">
    <div class="text-muted ms-3 mt-2">
      {{ limit * skip === 0 ? 1 : limit * skip }} -
      {{ limit * skip + limit }} {{ $t('of') }}
      {{ totalCount }}
    </div>
    <nav aria-label="Page navigation example">
      <ul class="pagination">
        <li class="page-item" :class="[{ disabled: !showPreviousLink() }]">
          <div
            @click="updatePage(skip - 1)"
            :style="{ cursor: 'pointer' }"
            aria-label="Previous"
            class="me-1 page-link border-0"
          >
            <i class="fas fa-backward"></i>
          </div>
        </li>
        <li v-if="limit * skip + limit < totalCount" class="page-item">
          <span class="page-link">
            <!-- {{ limit * skip + limit }} -->
            {{ skip + 1 }}
          </span>
        </li>
        <li v-else class="page-item">
          <span class="page-link">
            {{ Math.ceil(totalCount / limit) }}
          </span>
        </li>
        {{
          " "
        }}
        <p class="mb-0 pt-2 px-2">{{ $t('of') }}</p>
        {{
          " "
        }}
        <li class="page-item">
          <span class="page-link">
            {{ Math.ceil(totalCount / limit) }}
          </span>
        </li>
        <li class="page-item" :class="[{ disabled: !showNextLink() }]">
          <div
            @click="updatePage(skip + 1)"
            :style="{ cursor: 'pointer' }"
            class="page-link border-0"
            aria-label="Next"
          >
            <i class="fas fa-forward"></i>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: ["totalCount", "skip", "limit"],
  methods: {
    updatePage(pageNumber) {
      console.log(
        "🚀 ~ file: Pagination.vue:61 ~ updatePage ~ pageNumber",
        pageNumber
      );
      this.$emit("update-page", pageNumber);
    },
    totalPages() {
      return Math.ceil(this.table?.length / this.pageSize);
    },
    showPreviousLink() {
      return this.skip == 0 ? false : true;
    },
    showNextLink() {
      return this.totalCount <= this.limit * (this.skip + 1) ? false : true;
    },
  },
};
</script>

<style scoped>
</style>
<template>
  <div>
    <Multiselect
      v-model="value"
      :options="options"
      mode="tags"
      :close-on-select="false"
      :searchable="true"
      :noResultsText="noResults"
      @select="setMultiSelect"
      @deselect="setMultiSelect"
      @close="setMultiSelect"
      @clear="clearOptions"
      :track-by="label"
      :label="label"
      :valueProp="valueProp"
      :placeholder="placeholder"
    >
      <template
        ><strong>{{ option.firstName }}</strong></template
      >
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
export default {
  name: "VsudMultiSelect",
  data() {
    return {
      value: this.defaultValue,
    };
  },
  components: {
    Multiselect,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
      require: true,
    },
    defaultValue: {
      type: Array,
      default: () => [],
      require: true,
    },
    label: {
      type: String,
      default: "firstName",
    },

    noResults:{
      type:String,
      default: "",
    },

    valueProp: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  methods: {
    setMultiSelect() {
      this.$emit("set-select", JSON.stringify(this.value));
    },

    clearOptions(){
      this.$emit("clear-options","Clear Options");
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect__tags {
  background: var(--bs-primary);
}
</style>
<template>
  <div class="container">
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">{{ $t('confirmation') }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">{{ $t('delete_confirmation') }}?</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              {{ $t('no') }}
            </button>
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="deleteUser(toDelete)">
              {{ $t('yes') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4 pt-md-2 px-md-4">
          <div class="card-header pb-0 p-3">
            <div class="row">
              <div class="col-md-6">
                <div class="fw-bolder">{{ $t('projects_summary') }}</div>
              </div>
              <div class="col-md-6 text-end">
                <vsud-button color="primary" variant="gradient" @click="goToNewSheet">
                  {{ $t('download_report') }}
                </vsud-button>
              </div>
              <vsud-horzntl />
            </div>
          </div>
          <div class="card mb-4">
            <div class="card-body px-0 pt-0 pb-2">
              <div class="row d-flex justify-content-end mb-3">
                <div class="col-9">
                  <show-entries :value="query.limit" @select-range="selectRange" />
                </div>
                <div class="col-3 text-end">
                  <VsudDatePicker :textInput="true" :range="true" @date-range="dateRange" :has-label="false"
                    :start-date="''" :end-date="''" @cleared="dateCleared" :placeholder="$t('all_time_period')"></VsudDatePicker>
                </div>
              </div>
              <div class="row d-flex justify-content-end mb-3">
                <div class="col-6">
                  <div>
                    <vsud-multi-select
                      @set-select="handleMultiSelect"
                      :options="filteredUsers"
                      :placeholder="$t('all_users')"
                      label="fullName"
                      valueProp="_id"
                      class="multi_select_customize"
                      @clear-options="clearOptions"
                      :noResults="noResultsTextUsers"
                    ></vsud-multi-select>
                  </div>
                </div>
                <div class="col-6">
                  <div>
                    <vsud-multi-select @set-select="handleMultiSelectProject" :options="project_dropdown"
                      :placeholder="$t('all_projects')" label="projectName" valueProp="projectName"
                      class="multi_select_customize" @clear-options="clearProjects"
                      :noResults="noResultsTextProjects"></vsud-multi-select>
                  </div>
                </div>
              </div>
              <div class="table-responsive p-0">
                <table class="table align-items-left mb-0">
                  <thead style="background: #fafbfc">
                    <tr>
                      <th v-for="header of headers" :key="header" :class="
                        header === 'date'
                          ? `text-uppercase text-secondary text-xs
                                                  font-weight-bolder
                                                  text-dark
                                                  ps-2`
                          : `text-uppercase text-left text-secondary text-xs
                                                  font-weight-bolder
                                                  text-dark
                                                  ps-2`
                      ">
                        {{ header }}
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="filteredReports.length !== 0">
                    <tr v-for="ts in filteredReports.slice(
                      query.limit * query.page,
                      query.limit * query.page + query.limit
                    )" v-bind:key="ts._id">
                      <td>
                        <div class="d-flex justify-content-left px-2 py-1">
                          <div class="d-flex flex-column justify-content-left">
                            <p class="mb-0 text-xs fw-bold text-left">
                              {{ ts.projectName }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex justify-content-left px-2 py-1">
                          <div class="d-flex flex-column justify-content-left">
                            <p class="mb-0 text-xs fw-bold">
                              {{ ts.reportedHours }}
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td>
                        <div class="d-flex px-2 py-1">
                          <div class="
                                d-flex
                                flex-column
                                justify-content-left
                                align-items-start
                              ">
                            <p class="mb-0 fw-bold text-xs text-left">
                              {{}}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex justify-content-left px-2 py-1">
                          <div class="
                                d-flex
                                flex-column
                                justify-content-left
                                align-content-end
                              ">
                            <p class="mb-0 text-xs fw-bold text-left">
                              {{}}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex justify-content-left px-2 py-1">
                          <div class="d-flex flex-column justify-content-left">
                            <p>
                              {{ $t('no_record_found') }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex justify-content-left px-2 py-1">
                          <div class="d-flex flex-column justify-content-left">
                            <p class="mb-0 text-xs fw-bold text-left"></p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex justify-content-left px-2 py-1">
                          <div class="d-flex flex-column justify-content-left">
                            <p class="mb-0 text-xs fw-bold"></p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="my-auto pt-3 rounded-2" :style="{ background: '#FAFBFC' }">
                  <pagination :totalCount="filteredReports.length" :skip="query.page" :limit="query.limit"
                    @update-page="updatePage" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";
import VsudMultiSelect from "../components/VsudMultiSelect.vue";
import VsudHorzntl from "../components/VsudHorzntl.vue";
import axios from "axios";
import { ref } from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import VsudDatePicker from "../components/VsudDatePicker.vue";
import ShowEntries from "./components/ShowEntries.vue";
import Pagination from "./components/Pagination.vue";

import "@vuepic/vue-datepicker/dist/main.css";
export default {
  name: "timesheet",
  data() {
    return {
      timesheet: [],
      toDelete: "",
      project_dropdown: [],
      query: {
        limit: 10,
        page: 0,
      },
      headers: [this.$t('project'), this.$t('report_time')],
      dummyUsers: [],
      selectedUsers: [],
      selectedProjects: [],
      noResultsTextUsers: this.$t('no_records_found'),
      noResultsTextProjects: this.$t('no_record_found'),
      reports: [],
      startDate: "",
      endDate: "",
      csvHeader:
        "data:text/csv;charset=utf-8,Project,Reported Time\n",
      csvContent: "",
      hasReports: false,
    };
  },
  computed: {
    ...mapGetters(["getProjectLists", "getExistingUsers", "getUsersList"]),
    filteredReports() {
      let reports = [...this.reports];

     // console.log("r0", reports)

      // reports = reports.filter((report) => {
      //   let matchFound = false;
      //   this.getUsersList.forEach((user) => {
      //     if (user._id === report.userId) {
      //       matchFound = true;
      //       return;
      //     }
      //   });
      //   return matchFound;
      // });

      // console.log("r1",reports.length)
      // console.log("Selected users",this.selectedUsers)
      let project_name1 = {};
      let new_pro_array1 = [];
      reports.filter((report) => {
        if (report.projectName in project_name1) {
          project_name1[report.projectName] = project_name1[report.projectName] + report.reportedHours;
        } else {
          project_name1[report.projectName] = report.reportedHours;
        }
        return true;
      });
      for (var temp1 in project_name1) {
        new_pro_array1.push({ projectName: temp1, reportedHours: project_name1[temp1] });
      }


      let This = this;
      This.project_dropdown = new_pro_array1.sort((x, y) =>
        x.projectName.toLowerCase() < y.projectName.toLowerCase() ? -1 : 1
      );

      if (this.selectedUsers.length > 0) {
        reports = reports.filter((report) =>
          this.selectedUsers.includes(report.userId)
        );
      }
      if (this.selectedProjects.length > 0) {
        reports = reports.filter((report) =>
          this.selectedProjects.includes(report.projectName)
        );
      }
      if (this.startDate && this.endDate) {
        reports = reports.filter((report) => {
          const startDate = new Date(this.startDate).setHours(0, 0, 0, 0);
          const endDate = new Date(this.endDate).setHours(23, 59, 59, 999);
          const reportTime = new Date(report.date).getTime();
         // console.log(startDate, endDate, reportTime);
          if (reportTime >= startDate && reportTime <= endDate) {
            return true;
          } else {
            false;
          }
        });
      }
      reports.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });

    //  console.log("r2", reports.length)
      let project_name = {};
      let new_pro_array = [];
    //  console.log(project_name, new_pro_array)
      reports.filter((report) => {
        if (report.projectName in project_name) {
          project_name[report.projectName] = project_name[report.projectName] + report.reportedHours;
        } else {
          project_name[report.projectName] = report.reportedHours;
        }
        return true;
      });
      for (var temp in project_name) {
        new_pro_array.push({ projectName: temp, reportedHours: project_name[temp] });
      }

      // return new_pro_array.sort((x, y) =>
      //   x.projectName.toLowerCase() < y.projectName.toLowerCase() ? -1 : 1
      // );
      return new_pro_array.sort((x, y) =>
        x.reportedHours > y.reportedHours ? -1 : 1
      );
    },

    filteredUsers() {
      let users = [];
      let _allUsers = [...this.getUsersList];
      let _existingUsers = [...this.getExistingUsers];

      _existingUsers.forEach((eUser) => {
        _allUsers.forEach((aUser, index) => {
          if (eUser._id === aUser._id) {
            _allUsers.splice(index, 1);
          }
        });
      });

     // console.log("Deleted users", _allUsers);

      let deletedUsers = [..._allUsers];
      let reports = [...this.reports];
      let deletedUsersToShow = []

      reports.forEach((_r) => {
        deletedUsers.forEach((_d) => {
          if (_d._id === _r.userId) {
           // console.log("del user with report", _d._id)
            deletedUsersToShow.push(_d)
            return;
          }
        });
      });

      deletedUsersToShow = [...new Set(deletedUsersToShow)]

     // console.log("del users final", deletedUsersToShow.length);

      users = _existingUsers.concat(deletedUsersToShow);

users = users.sort((x, y) =>
        x.firstName.toLowerCase() < y.firstName.toLowerCase() ? -1 : 1
      );

     // console.log("userstoshow", users);

      return users;
    },

    currentWeek() {
      const currentDate = new Date();
      const currentDay = currentDate.getDay();
      const sunDiff = currentDate.getDate() - currentDay + 1;
      const satDiff = currentDate.getDate() + 6 - currentDay + 1;
      return [
        new Date(new Date(currentDate.setDate(sunDiff)).setHours(0, 0, 0, 0)),
        new Date(new Date(currentDate.setDate(satDiff)).setHours(0, 0, 0, 0)),
      ];
    },
  },
  components: {
    VsudButton,
    VsudMultiSelect,
    VsudDatePicker,
    VsudHorzntl,
    ShowEntries,
    Pagination,
    // VsudButton,
  },
  created() {
    var _isUserLoggedIn = localStorage.isUserLoggedIn;
    if (_isUserLoggedIn === undefined) {
      localStorage.isUserLoggedIn = "no";
      _isUserLoggedIn = localStorage.isUserLoggedIn;
    }
    if (_isUserLoggedIn === "no") {
      this.$router.push({
        path: `/sign-in`,
      });
      return;
    }

    this.getUsers();
    this.getNowExisitingUsers();
    this.getProjectList();
    //     const API = axios.create({ baseURL: "http://localhost:4040",
    // headers: {
    //   Authorization: "Bearer 411|yK3VkktZDBXu5BkQVrc15p9rHZWVQacmsZ5zHSm"
    // }});
    axios.get(`${process.env.VUE_APP_BASE_URL}/report`).then((res) => {
      if (res.data) {
        if (res.data && res.data.payload && res.data.payload.reports) {
          this.reports = res.data.payload.reports;
        }
      }
    });
  },
  mounted() {
    this.getNowExisitingUsers();
    this.getUsers();
    this.getProjectList();

    axios.get(`${process.env.VUE_APP_BASE_URL}/report`).then((res) => {
      if (res.data) {
        if (res.data && res.data.payload && res.data.payload.reports) {
          this.reports = res.data.payload.reports;
        }
      }
    });

    console.log("mounted", this.filteredReports);
    this.date = Array(2);
    this.date[0] = this.currentWeek[0];
    this.date[1] = this.currentWeek[1];

    //     const API = axios.create({ baseURL: "http://localhost:4040",
    // headers: {
    //   Authorization: "Bearer 411|yK3VkktZDBXu5BkQVrc15p9rHZWVQacmsZ5zHSm"
    // }});

    axios
      .get(
        `${process.env.VUE_APP_BASE_URL}/timesheet/admin/user/${this.date[0]}`
      )
      .then((res) => {
        if (res.data) {
          if (res.data && res.data.payload && res.data.payload.timesheet) {
            this.timesheet = res.data.payload.timesheet;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  setup() {
    const date = ref();
    return {
      date,
    };
  },

  watch: {
    selectedUsers(_new, _old) {
      console.log("_old", _old.length);
      console.log("_new", _new.length);
      if (_new.length !== 0) this.noResultsTextUsers = " ";
      else this.noResultsTextUsers = this.$t('no_record_found');
    },

    selectedProjects(_new, _old) {
      console.log("_old", _old.length);
      console.log("_new", _new.length);
      if (_new.length !== 0) this.noResultsTextProjects = " ";
      else this.noResultsTextProjects = this.$t('no_record_found');
    },
  },

  methods: {
    getUserName(userId) {
      let userFound = false;
      let uN = "";
      this.getUsersList.forEach((user) => {
        if (userFound) return;
        if (user._id === userId && user.fullName !== "") {
          uN = user.fullName;
          console.log("ID", user._id);
        }
      });
      return uN;
    },

    clearOptions(event) {
      console.log("event", event);
      this.selectedUsers = [];
    },

    clearProjects(event) {
      console.log("event", event);
      this.selectedProjects = [];
    },

    updatePage(page) {
      this.query = { ...this.query, page };
    },
    selectRange(e) {
      const { value } = e.target;
      this.query.limit = parseInt(value);
    },
    dateRange(payload) {
      const parsedValue = JSON.parse(payload);
      this.startDate = parsedValue[0];
      this.endDate = parsedValue[1];
    },
    handleMultiSelect(payload) {
      const multiselect = JSON.parse(payload);
      this.selectedUsers = multiselect;
    },
    handleMultiSelectProject(payload) {
      const multiselect = JSON.parse(payload);
      this.selectedProjects = multiselect;
    },
    ...mapMutations(["findUserById", "resetUser"]),
    ...mapActions([
      "getUsers",
      "deleteUser",
      "getProjectList",
      "getNowExisitingUsers",
    ]),
    onDelete(id) {
      this.toDelete = id;
    },
    fetchTimesheet() {
      if (this.date && this.date[0]) {
        //         const API = axios.create({ baseURL: "http://localhost:4040",
        // headers: {
        //  Authorization: "Bearer 411|yK3VkktZDBXu5BkQVrc15p9rHZWVQacmsZ5zHSm"}});

        axios
          .get(
            `${process.env.VUE_APP_BASE_URL}/timesheet/admin/user/${this.date[0]}`
          )
          .then((res) => {
            if (res.data) {
              if (res.data && res.data.payload && res.data.payload.timesheet) {
                this.timesheet = res.data.payload.timesheet;
                console.log(this.timesheet);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    dateCleared() {
      this.startDate = "";
      this.endDate = "";
    },
    goToNewSheet() {
      var report_time = 'All';
      var selected_pro = 'All';
      var display_user = 'All';
      var selected_user = [];
      const today_date = new Date();

      let day = today_date.getDate();
      let month = today_date.getMonth() + 1;
      let year = today_date.getFullYear();
      let hours = (today_date.getHours() < 10 ? '0' : '') + today_date.getHours();
      let mins = (today_date.getMinutes() < 10 ? '0' : '') + today_date.getMinutes();
      let secs = (today_date.getSeconds() < 10 ? '0' : '') + today_date.getSeconds();
      let today = day + '-' + month + '-' + year + 'T' + hours + 'H-' + mins + 'm-' + secs + 's';
      if (this.startDate != '') {
        var date = new Date(this.startDate);
        var result = "" + (date.getDate() > 9 ? '' : '0') + date.getDate() + '-' + ((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1) + '-' + date.getFullYear();
        var date1 = new Date(this.endDate);
        var result1 = "" + (date1.getDate() > 9 ? '' : '0') + date1.getDate() + '-' + ((date1.getMonth() + 1) > 9 ? '' : '0') + (date1.getMonth() + 1) + '-' + date1.getFullYear();
        report_time = result + ' To ' + result1;
      }
      if (this.selectedUsers.length > 0) {
        this.filteredUsers.forEach((record) => {
          if (this.selectedUsers.includes(record._id)) {
            selected_user.push(record.fullName)
          }
        })
        display_user = selected_user.join('/ ')
      }
      if (this.selectedProjects.length > 0) {
        selected_pro = this.selectedProjects.join('/ ');
      }
      this.csvContent = "data:text/csv;charset=utf-8,Users:," + display_user + ",\nProjects:," + selected_pro + ",\nReported Time:," + report_time + ",\n\nProject,Reported Time\n";
      this.filteredReports.forEach((record) => {
        this.csvContent += `${record.projectName},${record.reportedHours
          }\n`;
      });
      var csv_name = 'project-reports-' + today + '.csv';
      const encodedUri = encodeURI(this.csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", csv_name);
      document.body.appendChild(link);

      link.click();
    },
  },
};
</script>


<style scoped>
.no_records_found {
  background: red;
}

.datepicker__input {
  width: 250px;
}
</style>
<style>
.dp__input {
  height: 44px;
  border-radius: 4px;
  border-block: 3px 3px;
}

.dp__theme_light {
  --dp-primary-color: linear-gradient(310deg, #ff814f 0%, #ff814f 100%);
}

.dp__active_date {
  background: linear-gradient(310deg, #ff814f 0%, #ff814f 100%);
}

.dp__select {
  color: #ff814fff;
}

.multiselect {
  height: 44px;
  width: 260px;
  border-radius: 4px;
  display: table;
}

.multi_select_customize {
  --ms-ring-color: #ff814fff;
}
</style>
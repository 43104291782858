<template>
  <div class="projects-wrapper p-3 my-3 cursor-pointer pe-4">
    <div v-for="(activity, index) of activities" :key="activity._id">
      <div class="d-flex row justify-content-between py-2 task my-2">
        <div class="col">
          <p class="mb-0">{{ $t('activity') }}</p>
          <span>{{activity.activityName}}</span>
        </div>

        <div class="col">
          <p class="mb-0">{{ $t('estimated_hours') }}</p>
          <span>{{activity.estimatedHours}} {{ $t('hrs') }}</span>
        </div>

        <div class="col">
          <p class="mb-0">{{ $t('booked_hours') }}</p>
          <span>{{activity.bookedHours}} {{ $t('hrs') }}</span>
        </div>

        <div class="col task">
          <p class="mb-2">{{activity.completedPercentage}}% {{ $t('complete') }}</p>
          <div class="progress" style="height: 5px;">
          <div class="progress-bar" role="progressbar" :style="{width: `${activity.completedPercentage}%` }" style="background-color: #299702" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        </div>
      </div>
      <div class="divider" v-if="activities.length-1 != index"></div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ProjectTasks',
    props: {
      activities: {
        type: Array,
        required: true
      }
    }
  }
</script>
<style scoped>
.task span {
  font-weight: 500;
  font-size: 15px;
  line-height: 14px;
  color: #778399;
}

.task p {
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  color: #232360;
}

.divider {
  height: 1px;
  background: #E8EDF1;
}
</style>
<template>
  <nav
    class="
      shadow-none
      navbar
      bg-white
      navbar-main navbar-expand-lg
      border-radius-xl
    "
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="row px-3 py-1 container-fluid w-100">
      <div class="col-6">
        <breadcrumbs :currentPage="currentRouteName" :textWhite="textWhite" />
      </div>
      <div class="col-6">
        <div
          class="
            mt-2
            collapse
            d-flex
            justify-content-end
            navbar-collapse
            mt-sm-0
            me-md-0 me-sm-4
          "
          :class="this.$store.state.isRTL ? 'px-0' : 'me-sm-4'"
          id="navbar"
        >
          <ul class="navbar-nav d-flex justify-content-end">
            <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
              <a
                href="#"
                @click="toggleSidebar"
                class="p-0 nav-link text-body"
                id="iconNavbarSidenav"
              >
                <div class="sidenav-toggler-inner">
                  <i class="sidenav-toggler-line"></i>
                  <i class="sidenav-toggler-line"></i>
                  <i class="sidenav-toggler-line"></i>
                </div>
              </a>
            </li>
          </ul>
          <div class="locale-changer">
            <!-- <select v-model="$i18n.locale">
              <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</option>
            </select> -->
         </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions } from "vuex";
import i18n from '../../i18n';

export default {
  name: "navbar",
  data() {
    return {
      showMenu: false,
    };
  },
  props: ["minNav", "textWhite"],
  created() {
    this.minNav;
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },
    changeLocale(locale) {
                i18n.locale = locale;
            }
  },
  components: {
    Breadcrumbs,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  updated() {
    const navbar = document.getElementById("navbarBlur");
    window.addEventListener("scroll", () => {
      if (window.scrollY > 10 && this.$store.state.isNavFixed) {
        navbar.classList.add("blur");
        navbar.classList.add("position-sticky");
        navbar.classList.add("shadow-blur");
      } else {
        navbar.classList.remove("blur");
        navbar.classList.remove("position-sticky");
        navbar.classList.remove("shadow-blur");
      }
    });
  },
};
</script>

export const dateFromIso = (date) => {
  let dt = new Date(date);
  return new Date(
    `${dt.getFullYear()}/${zeroPad(dt.getMonth() + 1)}/${zeroPad(
      dt.getDate()
    )} ${zeroPad(dt.getHours())}:${zeroPad(dt.getMinutes())}:${zeroPad(
      dt.getSeconds()
    )}`
  );
};

export function convertIST(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [day, mnth, date.getFullYear()].join("/");
}

export const zeroPad = (num) => (Number(num) < 10 ? `0${num}` : num);

<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-12 mt-4">
        <div class="d-flex company-settings">
          <div class="me-5 w-60 card" style="background: #FFFFFF; border-radius: 16px;">
            <div class="p-3">
              <div class="d-flex justify-content-between">
                <div class="d-flex">
                  <img src="../assets/img/star.png" class="align-self-start">
                  <p class="ms-4">{{ $t('projects') }} </p>
                </div>
                <a class="view-all cursor-pointer" @click="$router.push('/projects')">{{ $t('view_all') }}</a>
              </div>
              <div class="divider"></div>
              <div class="row d-flex align-items-center mt-1">
                <div class="col-7">
                  <img src="../assets/img/line-chart.png">
                </div>
                <div class="col-5 d-flex flex-column">
                  <span class="total-projects">{{ $t('total_projects') }}</span>
                  <p class="ms-6 mt-1 project-count align-self-center">{{dashboardData.totalProjects}}</p>
                </div>
                <!-- <p class="more"><span style="color: #299702;">10+</span> more from last week</p> -->
              </div>
            </div>
          </div>
          <div class="p-3 w-75 text-white company-info">
            <div>
              <p class="header">{{dashboardData.companySettings.companyName}}</p>
              <div class="divider"></div>
            </div>
            <div class="d-flex mt-3 px-2">
              <div class="col-5 time-info">
                <span>{{ $t('from_time') }}</span>
                <p class="mb-0">{{dashboardData.companySettings.fromTime}}</p>
                <span>{{ $t('to_time') }}</span>
                <p class="mb-0">{{dashboardData.companySettings.toTime}}</p>
              </div>
              <div class="col-3 time-info">
                <span>{{ $t('daily_hours') }}</span>
                <p class="mb-0">{{
                  dashboardData.companySettings.dailyHours }}</p>
                <span>{{ $t('weekly_hours') }}</span>
                <p class="mb-0">{{
                dashboardData.companySettings.weeklyHours }}</p>
              </div>
              <div class="align-self-end col-4 ms-4">
                <a @click="$router.push('/compSettings')" class="text-white cursor-pointer view-more">{{ $t('view_more') }}</a>
              </div>
            </div>
          </div>
        </div>
        <!-- Projects with tasks -->
        <div class="rounded-circle circle"></div>
        <div class="">
          <div class="d-flex justify-content-between align-items-center">
            <h3 class="my-4 projects">{{ $t('top_10_projects') }}</h3>
            <button @click="$router.push('/projects')" class="btn mb-0 bg-gradient-primary btn-md viewp d-flex justify-content-center align-items-center position-relative">{{ $t('view_all') }}</button>
          </div>
<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="list-tab" data-bs-toggle="tab" data-bs-target="#list" type="button" role="tab" aria-controls="home" aria-selected="true">
<img src="../assets/img/list.png" class="list" width="15" height="15">
</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="graph-tab" data-bs-toggle="tab" data-bs-target="#graph" type="button" role="tab" aria-controls="profile" aria-selected="false">
<img src="../assets/img/bar-chart.png" class="bar-chart" width="15" height="15">
</button>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
<div class="tab-pane fade show active" id="list" role="tabpanel" aria-labelledby="list-tab">
          <div v-for="(project, i) of dashboardData.projectList" :key="project._id" class="my-2" @click="projectList(i)">
            <div class="projects-wrapper ps-0 pe-4 py-4 cursor-pointer">
              <div class="d-flex px-2 row justify-content-between py-2 project-detail align-items-center">
                <div class="d-flex col">
                  <img src="../assets/img/project-clock.png" class="p-clock">
                  <div>
                    <p class="mb-0 booked-hours">{{ $t('booked_hours') }}</p>
                    <span>{{project.bookedHours}} hrs</span>
                  </div>
                </div>
                <div class="col-4">
                  <p class="mb-2 pro-name">{{project.projectName}}</p>
                  <div>
                    <img src="../assets/img/clock.png" class="me-2">
                    <span>{{project.estimatedHours}} {{ $t('estimated_hours') }}</span>
                  </div>
                </div>
                <div class="d-flex align-self-center tasks col-2">
                  <p class="mb-0">
                    <img src="../assets/img/task-square.png"> 
                    <span class="ms-1">{{project.totalTasks}} {{ $t('tasks') }}</span>
                  </p>
                </div>
                <div class="col">
                  <p class="mb-2 complete">{{project.completedPercent}}% {{ $t('complete') }}</p>
                  <div class="progress" style="height: 5px;">
                  <div class="progress-bar" role="progressbar" :style="{width: `${project.completedPercent}%` }" style="background-color: #23B2FF" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                </div>
              </div>
            </div>
            <ProjectTasks :activities="project.activities"  class="collapse" :id="'collapseExample'+i"/>
          </div>
</div>
<div v-if="dashboardData.projectList.length>0" class="tab-pane fade" id="graph" role="tabpanel" aria-labelledby="graph-tab"><chart :ChartDashboard="dashboardData.projectList"/></div>
</div>

        </div>
      </div>
      <!-- right sidebar  -->
      <div class="col-lg-4 col-md-6 mt-4 col-sm-12">
        <div class="row">
          <div class="col-12 p-4 team-member-col">
            <team-members :TeamMembers="dashboardData.teamMembers"/>
          </div>
          <div class="col-12 mt-4 p-4 team-member-col">
            <priority-projects :ProjectPriority="dashboardData.projectPriority"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import TeamMembers from "./components/AdminDashboard/TeamMembers.vue";
  import PriorityProjects from "./components/AdminDashboard/PriorityProjects.vue";
  import ProjectTasks from "./components/AdminDashboard/ProjectTasks.vue";
import Chart from './components/AdminDashboard/ChartAdminDashboard.vue'

  import { mapActions, mapGetters } from "vuex";
  export default {
    name: 'AdminDashboard.vue',
    components: {
      TeamMembers,
      PriorityProjects,
      ProjectTasks,
Chart
    },
    created () {

      var _isUserLoggedIn = localStorage.isUserLoggedIn
    if(_isUserLoggedIn === undefined)
    {
      localStorage.isUserLoggedIn = "no"
      _isUserLoggedIn = localStorage.isUserLoggedIn
    }
    if(_isUserLoggedIn === "no"){
      this.$router.push({
        path: `/sign-in`,
      })
      return
    }

     this.fetchDashboardData();
    },
    computed: {
      ...mapGetters(['dashboardData']),

    },
    methods: {
      ...mapActions(["fetchDashboardData"]),
      projectList(index) {
        let id = "collapseExample"+index
        document.getElementById(id).classList.toggle("show")
      }
    }
  }

</script>
<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@500;700&display=swap');
  * {
    font-family: 'DM Sans';
  }

.header {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #FFFFFF;
}

.projects-wrapper {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
}

.company-info {
  background: #FF814F;
  border-radius: 16px;
}

.viewp {
  width: 116px;
  height: 36px;
  z-index: 99;
}

.circle {
  position: absolute;
  width: 300px;
  height: 300px;
  right: 354px;
  top: 187px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 64.89%);
  opacity: 0.5;
}

.time-info p {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #FFFFFF;
}

.time-info span {
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  color: #FFFFFF;
}

.projects {
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #232360;
}

.view-all {
  color: #FF814F;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
}

.project-count {
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #1E1E1E;
}

.more {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}

.cards {
  margin-right: -35px;
}

.total-projects {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #23235F;
}

.team-members {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #232360;
}

.team-member-col {
  background: #FFFFFF;
  border-radius: 16px;
}

.divider {
  height: 1px;
  background: #E8EDF1;
}

.booked-hours {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.pro-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #23235F;
}

.p-clock {
  width: 55px;
  height: 55px;
}

.view-more {
  position: relative;
  z-index: 999;
}

.complete {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #23235F;
}

@media screen and (max-width: 1024px) {
  .cards {
    margin-right: 0;
  }
  .company-settings {
    flex-direction: column;
  }
  .company-info {
    width: 100% !important;
  }
  .card {
    margin-right: 0!important;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 480px) {
  .p-clock {
    margin-left: -26px;
  }
  .tasks {
    margin: 20px 0 20px 0;
    align-self: unset !important;
  }
}
</style>

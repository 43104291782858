import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import InactiveProject from "@/views/InactiveProject.vue";
import NewProject from "@/views/NewProject.vue";
import EditProject from "@/views/EditProject.vue";
import NewUser from "@/views/NewUser.vue";
import Tables from "@/views/Tables.vue";
import InactiveEmployees from "@/views/InactiveEmployees.vue";
import Reports from "@/views/Reports.vue";
import ReportsSummary from "@/views/ReportsSummary.vue";
import Attendance from "@/views/Attendance.vue";
import AdminSettings from "@/views/AdminSettings.vue";
import CompanySettings from "@/views/CompanySettings.vue";
import Billing from "@/views/Billing.vue";
import VirtualReality from "@/views/VirtualReality.vue";
import Profile from "@/views/Profile.vue";
import Rtl from "@/views/Rtl.vue";
import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";
import AdminDashboard from '@/views/AdminDashboard.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import NewPassword from '@/views/NewPassword.vue';
import EmployeeOverview from '@/views/EmployeeOverview.vue';
import EmployeeTimesheet from '@/views/EmployeeTimesheet.vue';
import ProjectResources from '@/views/ProjectResources.vue';

const routes = [
    {
        path: "/",
        name: "/",
        redirect: "/dashboard",
    },
    {
        path: "/projects",
        name: "Projects",
        component: Dashboard,
    },
    {
        path: "/projects/inactive",
        name: "Inactive Projects",
        component: InactiveProject,
    },
    {
        path: "/projects/new",
        name: "New Project",
        component: NewProject,
    },
    {
        path: "/projects/edit/:id",
        name: "Edit Project",
        component: EditProject,
    },
    {
        path: "/projects/copy/:id",
        name: "Copy Project",
        component: NewProject,
    },
    {
        path: "/users/new",
        name: "New User",
        component: NewUser,
    },
    {
        path: "/users/overview/:id",
        name: "User Projects",
        component: EmployeeOverview,
    },
    {
        path: "/users/timesheet/:id",
        name: "Employee Timesheet",
        component: EmployeeTimesheet,
    },
    {
        path: "/projects/resources/:id",
        name: "Project Resources",
        component: ProjectResources,
    },

    {
        path: "/users/:id",
        name: "Edit User",
        component: NewUser,
    },
    {
        path: "/users",
        name: "Employees",
        component: Tables,
    },
    {
        path: "/users/inactive",
        name: "Employees Inactive",
        component: InactiveEmployees,
    },
    {
        path: "/reports-summary",
        name: "Summary Project",
        component: ReportsSummary,
    },
    {
        path: "/reports",
        name: "Project Report",
        component: Reports,
    },
    {
        path: "/attendance",
        name: "Attendance Report",
        component: Attendance,
    },
    {
        path: "/adminSettings",
        name: "AdminSettings",
        component: function () {
            if (window.location.origin == process.env.VUE_APP_DEMO_ORIGIN) {
                return AdminDashboard
            } else {
                return AdminSettings
            }
        }        
    },
    {
        path: "/compSettings",
        name: "CompSettings",
        component: CompanySettings,
    },
    {
        path: "/billing",
        name: "Billing",
        component: Billing,
    },
    {
        path: "/virtual-reality",
        name: "Virtual Reality",
        component: VirtualReality,
    },
    {
        path: "/profile",
        name: "Profile",
        component: Profile,
    },
    {
        path: "/rtl-page",
        name: "Rtl",
        component: Rtl,
    },
    {
        path: "/sign-in",
        name: "Sign In",
        component: SignIn,
    },
    {
        path: "/sign-up",
        name: "Sign Up",
        component: SignUp,
    },
    {
        path: '/dashboard',
        name: 'AdminDashboard',
        component: AdminDashboard
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: function () {
            if (window.location.origin == process.env.VUE_APP_DEMO_ORIGIN) {
                return SignIn
            } else {
                return ResetPassword
            }
        }
    },
    {
        path: '/new-password/:id',
        name: 'NewPassword',
        component: NewPassword
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active",
});

export default router;

import {
submitUser,
        getUsersList,
        getExistingUsers,
        getInactiveUsers,
        delUser,
        editUser,
} from "../../config/Apis/RestApis";
import { useToast } from "vue-toastification";
import project from "./project";

export default {
    state: {
        user: {
            firstName: "",
            lastName: "",
            username: "",
            password: "",
            confirmPassword: "",
            phoneNumber: "",
            department: "",
            dateOfBirth: "",
            status: true
        },
        users: [],
        existingUsers: [],
        inActiveUsers: [],
        projectState: project.state,
    },
    mutations: {
        handleNewUser(state, payload) {
            const {name, value} = payload.target;
            state.user[name] = value;
        },
        handleDate(state, payload) {
            const parsedValue = JSON.parse(payload);
            state.user.dateOfBirth = parsedValue;
        },
        findUserById(state, payload) {
            const found = state.users.find((user) => user._id === payload);
            state.user = JSON.parse(JSON.stringify(found));
        },
        findExistingUsersById(state, payload) {
            console.log("trigger");
            state.projectState.existingUsers.forEach((user) => {
                if (user._id === payload) {
                    state.user = user;
                    return;
                }
            });
            console.log(state.projectState.existingUsers);
        },
        findInactiveUsersById(state, payload) {
            console.log("trigger++1");
            state.inActiveUsers.forEach((user) => {
                if (user._id === payload) {
                    state.user = user;
                    return;
                }
            });
//            console.log(state.projectState.inActiveUsers);
        },
        handleSetStatus(state, payload) {
            const {name, value, checked} = payload.target;
            state.user[name] = value;
            if (name === "status") {
                state.user[name] = checked;
            }
        },
        resetUser(state) {
            state.user = {
                firstName: "",
                lastName: "",
                username: "",
                password: "",
                phoneNumber: "",
                department: "",
                dateOfBirth: "",
                status: true
            };
        },
    },
    actions: {
        async handleSubmit( { state, commit }) {
            const toast = useToast();
            const {
                firstName,
                lastName,
                username,
                password,
                phoneNumber,
                confirmPassword,
                department,
                dateOfBirth,
                status
            } = state.user;
            const validateEmail = (email) => {
                return String(email)
                        .toLowerCase()
                        .match(
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                );
            };
            function validatePassword(pw) {
                if (pw.length < 8) {
                    toast.warning("Your password must be at least 8 characters");
                    return false;
                }
                if (pw.length > 20) {
                    toast.warning("Your password must be less than 20 characters");
                    return false;
                }
                if (pw.search(/[a-z]/i) < 0) {
                    toast.warning("Your password must contain at least one letter.");
                    return false;
                }
                if (pw.search(/[0-9]/) < 0) {
                    toast.warning("Your password must contain at least one digit.");
                    return false;
                }
                return true;
            }
            if (firstName === "") {
                toast.warning(this.$t('first_name_cannot_be_empty'));
                return false;
            } else if (lastName === "") {
                toast.warning(this.$t('last_name_cannot_be_empty'));
                return false;
            } else if (username === "") {
                toast.warning(this.$t('email_cannot_be_empty'));
                return false;
            } else if (!validateEmail(username)) {
                toast.warning("Not a valid email id. Please verify");
                return false;
            } else if (password === "") {
                toast.warning(this.$t('password_cannot_be_empty'));
                return false;
            } else if (!validatePassword(password)) {
                // toast.warning("Please check the password");
                return false;
            } else if (password !== confirmPassword) {
                toast.warning("Passwords do not match");
                return false;
            } else if (department === "") {
                toast.warning("Department cannot be empty");
                return false;
            } else if (dateOfBirth === "") {
                toast.warning("Date of birth cannot be empty");
                return false;
            } else {
                try {
                    const toSubmitUser = {
                        firstName,
                        lastName,
                        username,
                        password,
                        phoneNumber,
                        department,
                        dateOfBirth,
                        status
                    };
                    console.log("ToSubmit", toSubmitUser);
                    const res = await submitUser(toSubmitUser);
                    commit("resetUser");
                    return !res.data.error;
                } catch (err) {
                    console.log("Status", err.response.status)
                    console.log("ErrorS", err.response)
                    if (err.response.data.code === "999") {
                        toast.error("This user already exists");
                    } else {
                        toast.error("Failed to create user");
                    }
                    console.log("🚀 ~ file: user.js ~ line 28 ~ handleSubmit ~ err", err);
                }
        }
        },
        async getUsers( { state }) {
            try {
                const {
                    data: {
                        payload: {users},
                    },
                } = await getUsersList();
                console.log("🚀 ~ file: user.js ~ line 43 ~ getUsers ~ users", users);
                state.users = users;
            } catch (err) {
                console.log("🚀 ~ file: project.js ~ line 104 ~ getUsers ~ err", err);
        }
        },
        async getInActiveUsers({ state }) {
            try {
                const {
                    data: {
                        payload: {users},
                    },
                } = await getInactiveUsers();
                const filteredUser = users.map((user) => {
                    user.fullName = user.firstName + " " + user.lastName;
                    return user;
                });
                state.inActiveUsers = filteredUser.sort((x, y) =>
                    x.fullName.toLowerCase() < y.fullName.toLowerCase() ? -1 : 1
                );
        console.log("🚀 ~ file: user.js ~ line 43 ~ getinactiveUsers ~ users", state.inActiveUsers);
//       state.existingUsers = filteredUser;                    
            } catch (err) {
                console.log("🚀 ~ file: project.js ~ line 104 ~ getUsers ~ err", err);
        }
        },
        async getExistingUsersAction( { state }) {
            try {
                const {
                    data: {
                        payload: {users},
                    },
                } = await getExistingUsers();
                const filteredUser = users.map((user) => {
                    user.fullName = user.firstName + " " + user.lastName;
                    return user;
                });
                state.existingUsers = filteredUser;
            } catch (err) {
                console.log("🚀 ~ file: project.js ~ line 104 ~ getUsers ~ err", err);
        }
        },
        async deleteUser( { dispatch }, payload) {
            const toast = useToast();
            try {
                const {
                    data: {error},
                } = await delUser(payload);
                if (!error) {
                    toast.success("Record Deleted Successfully");
                    dispatch("getExistingUsersAction");
                    window.location.reload();
                }
            } catch (err) {
                toast.error("Error Deleting Record");
                console.log("🚀 ~ file: user.js ~ line 51 ~ deleteUser ~ err", err);
        }
        },
        async handleEditUser( { state, commit }, payload) {
            const {
                firstName,
                lastName,
                username,
                department,
                dateOfBirth
            } = state.user;
            const toast = useToast();
            const validateEmail = (email) => {
                return String(email)
                        .toLowerCase()
                        .match(
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                );
            };
            // function validatePassword(pw) {
            //   if(pw === undefined){
            //     toast.warning("Password cannot be empty");
            //     return false;
            //   }
            //   if (pw.length < 8) {
            //     toast.warning("Your password must be at least 8 characters");
            //     return false;
            //   }
            //   if (pw.length > 20) {
            //     toast.warning("Your password must be less than 20 characters");
            //     return false;
            //   }
            //   if (pw.search(/[a-z]/i) < 0) {
            //     toast.warning("Your password must contain at least one letter.");
            //     return false;
            //   }
            //   if (pw.search(/[0-9]/) < 0) {
            //     toast.warning("Your password must contain at least one digit.");
            //     return false;
            //   }
            //   return true;
            // }
            if (firstName === "") {
                toast.warning('first name cannot be empty');
                return false;
            } else if (lastName === "") {
                toast.warning('last name cannot be empty');
                return false;
            } else if (username === "") {
                toast.warning("Username cannot be empty");
                return false;
            } else if (!validateEmail(username)) {
                toast.warning("Not a valid email id. Please verify");
                return false;
            }
            // else if (password === "") {
            //   toast.warning("Password cannot be empty");
            //   return false;
            // } else if (!validatePassword(password)) {
            //   toast.warning("Please check the password");
            //   return false;
            // }
            else if (department === "") {
                toast.warning("Department cannot be empty");
                return false;
            } else if (dateOfBirth === "") {
                toast.warning("Date of birth cannot be empty");
                return false;
            } else {
                try {
                    const res = await editUser(payload, state.user);
                    commit("resetUser");
                    return res.data;
                } catch (err) {
//                    console.log();
//                    toast.error("This is not allowed in demo version");
                    console.log("🚀 ~ file: user.js ~ line 56 ~ editUser ~ err", err);
                }
        }
        },
    },
};

<template>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header">
        <div class="container">
          <div class="row">
            <div class="col-md-12 d-flex flex-column">
              <div class="mt-3 card bg-white p-4 card-plain">
                <div class="card-header pb-0">
                  <vsud-horzntl :header="$t('company_details')" />
                </div>
                <div class="card-body w-md-50">
                  <div v-if="isGeneral">
                    <label for="">{{ $t('email') }}</label>
                    <input type="text" class="form-control form-control-lg rounded-1" v-model="email" />

                    <label for="">{{ $t('password') }}</label>
                    <input type="password" class="form-control form-control-lg rounded-1" v-model="password" />

                    <label>{{ $t('confirm_password') }}</label>
                    <div class="form-group">
                      <input type="password" class="form-control form-control-lg rounded-1" v-model="confirmPassword" />
                    </div>
                    <div class="text-center mb-10">
                      <vsud-button @click="saveSettings" class="my-4 mb-2" variant="gradient" color="primary"
                        full-width>Save
                      </vsud-button>
                    </div>
                  </div>
                  <div v-if="!isGeneral">
                    <div class="col mb-3">
                      <label for="">{{ $t('company_name') }}</label>
                      <input type="text" class="form-control form-control-lg rounded-1" v-model="compName" />
                    </div>
                    <div class="col mb-3">
                      <label for="">{{ $t('language') }}</label>
                      <select class="form-control" v-model="defaultLanguage">
                        <option value="en">English</option>
                        <option value="de">German</option>
                      </select>
                    </div>
                    <div class="col mb-3">
                      <label for="">{{ $t('from_time') }}</label>
                      <input type="time" class="form-control form-control-lg rounded-1" v-model="fromTime" />
                    </div>
                    <div class="col mb-3">
                      <label for="">{{ $t('to_time') }}</label>
                      <input type="time" class="form-control form-control-lg rounded-1" v-model="toTime" />
                    </div>
                    <div class="col mb-3">
                      <label for="">{{ $t('daily_hours') }}</label>
                      <input type="number" class="form-control form-control-lg rounded-1" v-model="dailyHours"
                        @keyup="numberChecker" />
                    </div>

                    <div class="col mb-3">
                      <label for="">{{ $t('weekly_hours') }}</label>
                      <input type="number" class="form-control form-control-lg rounded-1" v-model="weeklyHours"
                        @keyup="numberChecker" />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">{{ $t('set_working_days') }}</label>
                      <vsud-multi-select class="multi_select_customize" @set-select="setWorkingDays"
                        :defaultValue="workingDays" :options="[
                          'Sun',
                          'Mon',
                          'Tue',
                          'Wed',
                          'Thu',
                          'Fri',
                          'Sat',
                        ]"></vsud-multi-select>
                    </div>

                    <div class="text-center mb-3">
                      <vsud-button @click="saveSettings" class="my-4 mb-2 rounded-1" variant="gradient" color="primary"
                        full-width>Save
                      </vsud-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";
import VsudMultiSelect from "@/components/VsudMultiSelect.vue";
import VsudHorzntl from "../components/VsudHorzntl.vue";
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import { useToast } from 'vue-toastification';
export default {
  data() {
    return {
      compName: "",
      confirmPassword: "",
      fromTime: "09:00",
      toTime: "18:00",
      dailyHours: "8",
      weeklyHours: "40",
      workingDays: ["Mon", "Tue", "Wed", "Thu", "Fri"],
      email: "",
      password: "",
      isGeneral: false,
      defaultLanguage: ''
    };
  },
  components: {
    VsudButton,
    VsudMultiSelect,
    VsudHorzntl,
  },
  computed: {
    ...mapGetters(["getAdmin"]),
    _dailyHours() {
      var _dH = this.dailyHours === "" ? 0 : this.dailyHours
      return _dH
    },
    _weeklyHours() {
      var _wH = this.weeklyHours === "" ? 0 : this.weeklyHours
      return _wH
    }
  },

  created() {
    var _isUserLoggedIn = localStorage.isUserLoggedIn
    if (_isUserLoggedIn === undefined) {
      localStorage.isUserLoggedIn = "no"
      _isUserLoggedIn = localStorage.isUserLoggedIn
    }
    if (_isUserLoggedIn === "no") {
      this.$router.push({
        path: `/sign-in`,
      })
      return
    }
  },

  mounted() {

    //    const API =  axios.create({ baseURL: "http://localhost:4040", headers: {
    //   Authorization: "Bearer 411|yK3VkktZDBXu5BkQVrc15p9rHZWVQacmsZ5zHSm"
    // }});

    axios.get(`${process.env.VUE_APP_BASE_URL}/settings`).then((res) => {
      if (res.data && res.data.payload && res.data.payload.settings) {
        const settings = res.data.payload.settings;
        this.email = settings.email;
        this.password = settings.password;
        this.confirmPassword = settings.password;
        this.fromTime = settings.fromTime;
        this.toTime = settings.toTime;
        this.weeklyHours = settings.weeklyHours;
        this.dailyHours = settings.dailyHours;
        this.workingDays = settings.workingDays;
        this.compName = settings.compName;
        this.defaultLanguage = settings.language;
      }
    });
  },
  methods: {
    ...mapMutations(["handleAdminData"]),

    numberChecker(event) {
      var currentVal = event.target.value
      if (currentVal < 0) {
        event.target.value = ""
      }
      else {
        var _numStr = currentVal.toString()
        if (_numStr.length > 4) {
          _numStr = _numStr.substring(0, _numStr.length - 1);
        }
        var parsedValue = parseInt(_numStr)
        event.target.value = parsedValue
      }
    },

    handleSubmit() { },
    saveSettings() {
      const toast = useToast()
      if (this.password === this.confirmPassword) {

        const toSaveDetails = {
          fromTime: this.fromTime,
          toTime: this.toTime,
          weeklyHours: this._weeklyHours,
          dailyHours: this._dailyHours,
          workingDays: this.workingDays,
          email: this.email,
          password: this.password,
          compName: this.compName,
          language: this.defaultLanguage,
        }

        console.log(this._weeklyHours, this._dailyHours)

        axios
          .post(`${process.env.VUE_APP_BASE_URL}/settings`, toSaveDetails)
          .then(() => {
            // this.$router.push({
            //   path:`/users`
            // })
            toast.success(this.$t('details_saved_successfully'))
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          })
          .catch(() => {
            toast.error("Failed to save details")
          });
      } else {
        alert("password doesnt match");
      }
    },
    setWorkingDays(value) {
      this.workingDays = JSON.parse(value);
    },
  },
};
</script>

<style scoped>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
<style>
.multiselect-tag {
  background: linear-gradient(310deg, #ff814f 0%, #ff814f 100%);
}

.multi_select_customize {
  --ms-ring-color: #ff814fff;
}

;
</style>
<template>
  <div>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.1 6.52822C23.1 4.4648 21.4138 2.78291 19.3327 2.74764L19.3324 2.74685H19.2659H17.3232L17.323 1.43862C17.323 1.14021 17.0771 0.9 16.7781 0.9C16.4789 0.9 16.2332 1.14043 16.2332 1.43863V2.7444H7.76656V1.43863C7.76656 1.14021 7.52061 0.9 7.22163 0.9C6.92242 0.9 6.6767 1.14043 6.6767 1.43863V2.7444H4.73404C2.62215 2.7444 0.9 4.44031 0.9 6.52555V19.3188C0.9 21.4043 2.62234 23.1 4.73404 23.1H19.266C21.3779 23.1 23.1 21.4041 23.1 19.3188V6.52822ZM17.3228 4.85491V3.82429H19.2655C20.779 3.82429 22.0097 5.03954 22.0097 6.52818V8.36976H1.98977V6.52818C1.98977 5.03954 3.22041 3.82429 4.73394 3.82429H6.6766V4.8552C6.18357 5.06443 5.83717 5.54667 5.83717 6.10955C5.83717 6.86398 6.4598 7.47571 7.22151 7.47571C7.98323 7.47571 8.60586 6.86393 8.60586 6.10955C8.60586 5.5471 8.26004 5.06232 7.76642 4.85491V3.82429H16.233V4.8552C15.74 5.06443 15.3936 5.54667 15.3936 6.10955C15.3936 6.86398 16.0162 7.47571 16.7779 7.47571C17.5397 7.47571 18.1623 6.86393 18.1623 6.10955C18.1623 5.5471 17.8165 5.06232 17.3228 4.85491ZM22.01 19.3213C22.01 20.8124 20.7795 22.0251 19.2659 22.0251H4.73394C3.22041 22.0251 1.98977 20.8099 1.98977 19.3213V9.44431H22.01V19.3213Z"
      
        fill="#8E92BC"
        stroke="#8E92BC"
        stroke-width="0.9"
      />
      <path
        d="M10.8845 19.0203V19.0203L10.8863 19.0202C11.0542 19.0172 11.212 18.9348 11.3112 18.8004C11.3112 18.8003 11.3113 18.8003 11.3113 18.8002L16.4229 11.9368L16.423 11.9366C16.6002 11.6976 16.5502 11.3603 16.3052 11.1829L16.3052 11.1829L16.3051 11.1828C16.0643 11.0094 15.7243 11.0575 15.5444 11.299L15.5444 11.299L10.85 17.6047L8.47893 14.7347C8.47891 14.7347 8.4789 14.7347 8.47888 14.7347C8.29016 14.5059 7.94706 14.469 7.71446 14.6583C7.48237 14.8447 7.44417 15.1851 7.63732 15.4157C7.63741 15.4158 7.6375 15.4159 7.63759 15.416L10.4509 18.8224L10.4886 18.868L10.4948 18.8668C10.596 18.9648 10.734 19.0203 10.8745 19.0203H10.8845Z"
        fill="#8E92BC"
        stroke="#8E92BC"
        stroke-width="0.9"
      />
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>
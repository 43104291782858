import { createStore } from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import project from "./modules/project";
import user from "./modules/user";
import admin from "./modules/admin";
export default createStore({
    state: {
        hideConfigButton: false,
        isPinned: true,
        showConfig: false,
        isTransparent: "",
        isRTL: false,
        color: "",
        homepageName: "Home",
        homepageHref: "/",
        isNavFixed: false,
        isAbsolute: false,
        showNavs: true,
        showSidenav: true,
        showNavbar: true,
        showFooter: true,
        showMain: true,
        navbarFixed:
                "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
        absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
        bootstrap,
        projectState: project.state,
        userState: user.state,
        adminState: admin.state,
        projectNameResource: "",
        employeeNameOverview: "",
        currentPage: 0,
        origin: window.location.origin,
        demoOrigin: process.env.VUE_APP_DEMO_ORIGIN
    },
    mutations: {
        toggleConfigurator(state) {
            state.showConfig = !state.showConfig;
        },

        navbarMinimize(state) {
            const sidenav_show = document.querySelector(".g-sidenav-show");
            if (sidenav_show.classList.contains("g-sidenav-pinned")) {
                sidenav_show.classList.remove("g-sidenav-pinned");
                sidenav_show.classList.add("g-sidenav-hidden");
                state.isPinned = false;
            } else {
                sidenav_show.classList.add("g-sidenav-pinned");
                sidenav_show.classList.remove("g-sidenav-hidden");
                state.isPinned = true;
            }
        },
        sidebarType(state, payload) {
            state.isTransparent = payload;
        },
        cardBackground(state, payload) {
            state.color = payload;
        },
        navbarFixed(state) {
            if (state.isNavFixed === false) {
                state.isNavFixed = true;
            } else {
                state.isNavFixed = false;
            }
        },
        toggleEveryDisplay(state) {
            state.showNavbar = !state.showNavbar;
            state.showSidenav = !state.showSidenav;
            state.showFooter = !state.showFooter;
        },
        toggleHideConfig(state) {
            state.hideConfigButton = !state.hideConfigButton;
        },
        setHomeHeader(state, payload) {
            state.homepageName = payload;
        },
        setHomeHref(state, payload) {
            state.homepageHref = payload;
        },
    },
    actions: {
        toggleSidebarColor( { commit }, payload) {
            commit("sidebarType", payload);
        },
        setCardBackground( { commit }, payload) {
            commit("cardBackground", payload);
        },
    },
    modules: {
        project,
        user,
        admin,
    },
    getters: {
        getProject(state) {
            return state.projectState.project;
        },
        getProjectLists(state) {
            return (selectedUsers) => {
                let selectedUsersProjects = []

                if (selectedUsers.length === 0) {
                    var _projects = state.projectState.projectList
                    return _projects

                } else {
                    state.projectState.projectList.forEach(project => {
                        project.users.forEach(user => {
                            selectedUsers.forEach(selectedUser => {
                                if (user === selectedUser) {
                                    selectedUsersProjects.push(project)
                                }
                            })
                        })
                    })
                    return selectedUsersProjects
                }
            }
        },

        getUsersList(state) {
            return state.projectState.users;
        },
        getUsers(state) {
            return state.userState.user;
        },
        getAllUsersList(state) {
            return state.userState.users;
        },
        getInactiveProject(state) {
            state.projectState.inactiveProjects = state.projectState.inactiveProjects.sort((x, y) =>
                x.projectName.toLowerCase() < y.projectName.toLowerCase() ? -1 : 1
            );
            console.log("QWERTY", state.projectState.inactiveProjects);
            return state.projectState.inactiveProjects;
        },
        getExistingProjects(state) {
            state.projectState.existingProjects = state.projectState.existingProjects.sort((x, y) =>
                x.projectName.toLowerCase() < y.projectName.toLowerCase() ? -1 : 1
            );
//        console.log("QWERTY",state.projectState.existingProjects);
            return state.projectState.existingProjects;
        },
        getExistingUsers(state) {
            console.log("Users...To>", state.projectState.existingUsers)
            return state.projectState.existingUsers
        },
        getInActiveUser(state) {
            console.log("Users", state.userState.inActiveUsers)
            return state.userState.inActiveUsers
        },
        getAdmin(state) {
            return state.adminState.admin;
        },
    },
});

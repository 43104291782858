<template>
  <vsud-alert
    :dismissible="dismiss"
    @dismiss-alert="dismiss = false"
    :color="color"
    >{{ message }}</vsud-alert
  >
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header">
        <div class="container">
          <div class="row">
            <div class="col-12 d-flex flex-column">
              <div class="mt-3 card bg-white p-4">
                <div class="card-header pb-0">
                  <vsud-horzntl :header="$t('admin_settings')" />
                </div>
                <div class="card-body w-md-50">
                  <div v-if="isGeneral">
                    <label for="">{{ $t('email') }}</label>
                    <div class="input-group">
                      <span
                        class="input-group-text custom_span"
                        id="basic-addon1"
                      >
                        <div
                          class="
                            d-flex
                            justify-content-center
                            align-items-center
                            ms-2
                          "
                        >
                          <email-icon />
                        </div>
                      </span>

                      <input
                        type="text"
                        class="
                          form-control
                          email-input
                          form-control-lg
                          rounded-1
                          box_size_custom
                        "
                        v-model="email"
                      />
                    </div>
                    <label for="">{{ $t('password') }}</label>
                    <div class="input-group">
                      <span
                      class="input-group-text custom_span"
                        id="basic-addon1"
                      >
                        <div
                          class="
                            d-flex
                            justify-content-center
                            align-items-center
                            ms-2
                          "
                        >
                          <password-icon />
                        </div>
                      </span>
                      <input
                        type="password"
                        class="
                          form-control
                          email-input
                          form-control-lg
                          rounded-1
                          box_size_custom
                        "
                        v-model="password"
                      />
                    </div>
                    <label>{{ $t('confirm_password') }}</label>
                    <div class="input-group">
                      <span
                      class="input-group-text custom_span"
                        style="width: 53px"
                        id="basic-addon1"
                      >
                        <div
                          class="
                            d-flex
                            justify-content-center
                            align-items-center
                            ms-2
                          "
                        >
                          <password-icon />
                        </div>
                      </span>
                      <input
                        type="password"
                        class="form-control form-control-lg rounded-1 box_size_custom"
                        v-model="confirmPassword"
                      />
                    </div>
                    <div class="text-center mb-10">
                      <vsud-button
                        @click="saveSettings"
                        class="my-4 mb-2 rounded-1"
                        variant="gradient"
                        color="primary"
                        full-width
                        >{{ $t('save') }}
                      </vsud-button>
                    </div>
                  </div>
                  <div v-if="!isGeneral">
                    <div class="col">
                      <label for="">{{ $t('company_name') }}</label>
                      <input
                        type="text"
                        class="form-control form-control-default"
                        v-model="compName"
                      />
                    </div>
                    <div class="col">
                      <label for="">{{ $t('from_time') }}</label>
                      <input
                        type="time"
                        class="form-control form-control-default"
                        v-model="fromTime"
                      />
                    </div>
                    <div class="col">
                      <label for="">{{ $t('to_time') }}</label>
                      <input
                        type="time"
                        class="form-control form-control-default"
                        v-model="toTime"
                      />
                    </div>
                    <div class="col">
                      <label for="">{{ $t('daily_hours') }}</label>
                      <input
                        type="number"
                        class="form-control form-control-default"
                        v-model="dailyHours"
                      />
                    </div>

                    <div class="col">
                      <label for="">{{ $t('weekly_hours') }}</label>
                      <input
                        type="number"
                        class="form-control form-control-default"
                        v-model="weeklyHours"
                      />
                    </div>
                    <div>
                      <label class="form-label">{{ $t('set_working_days') }}</label>
                      <vsud-multi-select
                        @set-select="setWorkingDays"
                        :defaultValue="workingDays"
                        :options="[
                          'Sun',
                          'Mon',
                          'Tue',
                          'Wed',
                          'Thu',
                          'Fri',
                          'Sat',
                        ]"
                      ></vsud-multi-select>
                    </div>

                    <div class="text-center mb-10">
                      <vsud-button
                        @click="saveSettings"
                        class="my-4 mb-2"
                        variant="gradient"
                        color="success"
                        full-width
                        >{{ $t('save') }}
                      </vsud-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";
import VsudAlert from "../components/VsudAlert.vue";
import VsudMultiSelect from "@/components/VsudMultiSelect.vue";
import VsudHorzntl from "../components/VsudHorzntl.vue";
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import EmailIcon from "../components/Icon/EmailIcon.vue";
import PasswordIcon from "../components/Icon/PasswordIcon.vue";
export default {
  data() {
    return {
      compName: "",
      confirmPassword: "",
      fromTime: "09:00",
      toTime: "18:00",
      dailyHours: "8",
      weeklyHours: "40",
      workingDays: ["Mon", "Tue", "Wed", "Thu", "Fri"],
      email: "",
      password: "",
      isGeneral: true,
      dismiss: false,
      message: "",
      color: "warning",
    };
  },
  components: {
    VsudButton,
    VsudMultiSelect,
    VsudAlert,
    VsudHorzntl,
    EmailIcon,
    PasswordIcon,
  },
  computed: {
    ...mapGetters(["getAdmin"]),
  },

  created(){
    var _isUserLoggedIn = localStorage.isUserLoggedIn
    if(_isUserLoggedIn === undefined)
    {
      localStorage.isUserLoggedIn = "no"
      _isUserLoggedIn = localStorage.isUserLoggedIn
    }
    if(_isUserLoggedIn === "no"){
      this.$router.push({
        path: `/sign-in`,
      })
      return
    }
  },

  mounted() {
    axios.get(`${process.env.VUE_APP_BASE_URL}/settings`).then((res) => {
      if (res.data && res.data.payload && res.data.payload.settings) {
        const settings = res.data.payload.settings;
        this.email = settings.email;
        this.password = settings.password;
        this.confirmPassword = settings.password;
        this.fromTime = settings.fromTime;
        this.toTime = settings.toTime;
        this.weeklyHours = settings.weeklyHours;
        this.dailyHours = settings.dailyHours;
        this.workingDays = settings.workingDays;
        this.compName = settings.compName;
      }
    });
  },
  methods: {
    ...mapMutations(["handleAdminData"]),
    handleSubmit() {},
    saveSettings() {
      if (this.password === this.confirmPassword) {
        axios
          .post(`${process.env.VUE_APP_BASE_URL}/settings`, {
            fromTime: this.fromTime,
            toTime: this.toTime,
            weeklyHours: this.weeklyHours,
            dailyHours: this.dailyHours,
            workingDays: this.workingDays,
            email: this.email,
            password: this.password,
            compName: this.compName,
          })
          .then(() => {
            this.color = "success";
            this.dismiss = true;
            this.message = this.$t('saved');
          })
          .catch(() => {});
      } else {
        this.color = "warning";
        this.dismiss = true;
        this.message = this.$t('password_does_not_match');
      }
    },
    setWorkingDays(value) {
      this.workingDays = JSON.parse(value);
    },
  },
};
</script>

<style scoped>
.email-icon {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 5%;
}
.email-input {
  padding-left: 9%;
}


</style>
<style>
.box_size_custom{
  height:44.5px;
}

.custom_span{
  height:44.5px;
  width:48px;
}

</style>
<template>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header">
        <div class="container">
          <div class="row overflow-auto">
            <div class="col-md-12 d-flex flex-column">
              <div class="mt-3 card bg-white p-4">
                <div class="card-header pb-0">
                  <!-- <vsud-horzntl :header="this.$t('edit_project')" v-if="this.$route.params.id" /> -->
                  <vsud-horzntl :header="this.$t('new_project')" />
                </div>
                <div class="card-body">
                  <div class="col">
                    <div class="row">
                      <div class="col-md-6">
                        <label>{{ $t('project_name2') }}</label>
                        <div class="form-group">
                          <input id="task" type="text" class="project_name_style form-control form-control-default"
                            name="projectName" :value="getProject.projectName" @input="handleSetNewProject" />
                        </div>
                      </div>
                      <div class="col-md-6">

                        <vsud-select :options="getExistingUsers" valueKey="_id" optionKey="fullName"
                          placeHolder="Select Manager" :value="getProject.projectManager"
                          @change-select="handleSetNewProject" name="projectManager">{{ $t('project_manager')
                          }}</vsud-select>

                        <!-- <label class="form-label">Project Manager</label>
                        <vsud-multi-select
                          @set-select="handleSetNewProject"
                          :options="getExistingUsers"
                          :defaultValue="getProject.users"
                          label="fullName"
                          valueProp="_id"
                        ></vsud-multi-select> -->

                      </div>
                    </div>
                    <div>
                      <label class="form-label">{{ $t('assign_users') }}</label>
                      <vsud-multi-select @set-select="handleMultiSelect" :options="getExistingUsers"
                        :defaultValue="getProject.users" label="fullName" valueProp="_id"></vsud-multi-select>
                    </div>
                    <div>
                      <VsudTextarea :id="1" name="comments" :value="getProject.comments"
                        @handle-change="handleSetNewProject">{{ $t('comments') }}</VsudTextarea>
                    </div>
                    <div class="row d-flex align-items-center">
                      <div class="col-2 pe-0" style="width: 125px">
                        <label class="my-2">{{ $t('project_visibility') }}</label>
                      </div>
                      <div class="col-1 ps-0">
                        <div class="form-check form-switch">
                          <input :checked="getProject.projectVisibility" :value="getProject.projectVisibility"
                            name="projectVisibility" @change="handleSetNewProject" type="checkbox" role="switch"
                            class="form-check-input ps-0" />
                        </div>
                      </div>
                    </div>
                    <!-- <div class="d-flex justify-content-evenly"> -->
                    <!-- <div
                          v-for="option of [
                            { name: 'enable', value: true },
                            { name: 'disable', value: false },
                          ]"
                          :key="option.name"
                        >
                          <vsud-radio
                            name="projectVisibility"
                            :id="option.name"
                            @toggle-radio="handleSetNewProject"
                            :value="option.value"
                            :defaultValue="getProject.projectVisibility"
                            >{{ option.name }}</vsud-radio
                          >
                        </div> -->
                    <!-- </div> -->
                    <div class="col-3">
                      <VsudDatePicker :textInput="true" :range="true" :startDate="new Date(getProject?.startDate)"
                        :endDate="new Date(getProject?.endDate)" @date-range="dateRange">{{ $t('project_duration') }}
                      </VsudDatePicker>
                    </div>
                    <div>
                      <AddActivities :newActivity="getProject.activities" :addActivity="addActivity"
                        :addActivityList="addActivityList" :delete-activity="deleteActivity1"
                        :activities="activitiesList" />
                    </div>
                    <div class="d-flex align-content-center mt-2">
                      <label class="form-label">{{ $t('hours_estimated') }} : {{ getProject.hoursBooked }}</label>
                    </div>
                  </div>
                  <div class="text-start">
                    <!-- <vsud-button v-if="setIsEditing()" v-on:click="handleEdit" class="my-4 mb-2 w-md-25"
                      variant="gradient" color="primary">{{ $t('save') }}
                    </vsud-button> -->
                    <vsud-button v-on:click="handleSubmit" class="my-4 mb-2 w-md-25" variant="gradient"
                      color="primary">{{ $t('add_project') }}
                    </vsud-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";
import VsudHorzntl from "../components/VsudHorzntl.vue";
const body = document.getElementsByTagName("body")[0];
import { mapGetters, mapMutations, mapActions } from "vuex";
// import axios from "axios";
import VsudTextarea from "../components/VsudTextarea.vue";
// import VsudRadio from "../components/VsudRadio.vue";
import VsudDatePicker from "../components/VsudDatePicker.vue";
import VsudSelect from "../components/VsudSelect.vue";
import AddActivities from "./components/AddActivities.vue";
import VsudMultiSelect from "../components/VsudMultiSelect.vue";
import { useToast } from "vue-toastification";
import { useRoute } from "vue-router";

import { getProject } from "../config/Apis/RestApis";

export default {
  name: "AddTimesheet",
  components: {
    VsudButton,
    VsudTextarea,
    // VsudRadio,
    VsudDatePicker,
    VsudSelect,
    AddActivities,
    VsudMultiSelect,
    VsudHorzntl,
  },
  data() {
    return {
      users: ["damon", "stefan", "elena", "caroline", "ben"],
      projectId: "",
    };
  },
  computed: {
    ...mapGetters(["getProject", "getExistingUsers",]),
  },
  mounted() {
    const route = useRoute();
    this.setHomeHeader("Projects");
    this.setHomeHref("/projects");
    const id = route.params.id;
    this.projectId = id;
  },
  created() {
    var _isUserLoggedIn = localStorage.isUserLoggedIn
    if (_isUserLoggedIn === undefined) {
      localStorage.isUserLoggedIn = "no"
      _isUserLoggedIn = localStorage.isUserLoggedIn
    }
    if (_isUserLoggedIn === "no") {
      this.$router.push({
        path: `/sign-in`,
      })
      return
    }

    console.log(this.$route.params);
    // this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
    this.getExistingUsersAction();
    this.getNowExisitingUsers();
  },
  beforeUnmount() {
    // this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  unmounted() {
    this.setHomeHeader("Home");
    this.setHomeHref("/");
    this.resetProject();
  },
  methods: {
    ...mapMutations([
      "toggleEveryDisplay",
      "toggleHideConfig",
      "setProject",
      "handleSetNewProject",
      "dateRange",
      "addActivity",
      "addActivityList",
      "handleMultiSelect",
      "resetProject",
      "setHomeHeader",
      "setHomeHref",
    ]),
    ...mapActions(["deleteActivity1", "handleSubmitProject", "handleEditProject", "getExistingUsersAction", "getNowExisitingUsers"]),
    // onSignin() {
    //   axios
    //     .post(
    //       "https://webglobic-api.dailytime.io/project",
    //       {
    //         name: this.projectName,
    //       },
    //       { withCredentials: true }
    //     )
    //     .then(() => {
    //       this.$router.push(`/projects`);
    //     });
    // },
    async handleSubmit() {
      const res = await this.handleSubmitProject();
      console.log(res);
      console.log(
        "🚀 ~ file: NewProject.vue ~ line 222 ~ handleSubmit ~ res",
        res
      );
      const toast = useToast();
      if (res) {
        toast.success(this.$t('successfully_created_the_project'));
        this.$router.push(`/projects`);
      }
    },
    setIsEditing() {
      const route = useRoute();
      const id = route.params.id;
      if (id) {
        getProject()
        return true;
      }
      return false;
    },
    // async handleEdit() {
    //   const res = await this.handleEditProject(this.projectId);
    //   const toast = useToast();
    //   if (res) {
    //     toast.success(this.$t('successfully_updated_the_project'));
    //     //  this.$router.push(`/projects`);
    //     this.$router.go(-1);
    //   }
    // },
  },
};
</script>
<style scoped>
.form-switch .form-check-input:checked {
  border-color: var(--bs-primary);
  background-color: var(--bs-primary);
}

.project_name_style {
  height: 44.5px;
  border-radius: 4px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>

<style>
.dp__input {
  height: 44px;
  border-radius: 4px;
  border-block: 3px 3px;
}

.dp__theme_light {
  --dp-primary-color: linear-gradient(310deg, #ff814f 0%, #ff814f 100%);
}

.dp__active_date {
  background: linear-gradient(310deg, #ff814f 0%, #ff814f 100%);
}

.dp__select {
  color: #ff814fff;
}

.multiselect {
  height: 44px;
  width: 100%;
  border-radius: 4px;
  display: table;
}

.multi_select_customize {
  --ms-ring-color: #ff814fff;
}

;
</style>
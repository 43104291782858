<template>
  <div>
    <svg
      class="password-icon"
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9692 6.3999H2.24615C1.55792 6.3999 1 6.93716 1 7.5999V11.7999C1 12.4626 1.55792 12.9999 2.24615 12.9999H10.9692C11.6575 12.9999 12.2154 12.4626 12.2154 11.7999V7.5999C12.2154 6.93716 11.6575 6.3999 10.9692 6.3999Z"
        stroke="#72758A"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.49231 6.4V4C3.49231 3.20435 3.82054 2.44129 4.40478 1.87868C4.98903 1.31607 5.78144 1 6.60769 1C7.43395 1 8.22636 1.31607 8.8106 1.87868C9.39485 2.44129 9.72308 3.20435 9.72308 4V6.4"
        stroke="#72758A"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: ["customClass"],
};
</script>

<style lang="css" scoped>
.password-icon {
  /* position: absolute;
  top: 39%;
  transform: translate(-50%, -50%);
  left: 6%; */
}
</style>
<template>
  <nav aria-label="breadcrumb" class="bg-white">
    <ol
      class="px-0 pt-1 pb-0 mb-0 bg-white breadcrumb"
      :class="this.$store.state.isRTL ? '' : ' me-sm-6'"
    >
      <li class="text-sm breadcrumb-item" :class="textWhite">
        <a
          v-if="this.$store.state.isRTL"
          :class="textWhite"
          class="opacity-5 ps-2"
          href="/"
          ></a
        >
        <a v-else :class="textWhite" class="opacity-8" :href="homepageHref">{{
          homepageName
        }}</a>
      </li>
      <li
        class="text-sm breadcrumb-item active"
        :class="textWhite ? 'text-white' : 'text-dark'"
        aria-current="page"
      >
        {{ currentPage }}
      </li>
    </ol>
    <!-- <h6 class="mb-0 font-weight-bolder" :class="textWhite ? 'text-white' : ''">
      {{ currentPage }}
    </h6> -->
  </nav>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "breadcrumbs",
  props: {
    currentPage: {
      required: true,
    },
    textWhite: {
      type: String,
    },
  },
  computed: {
    ...mapState(["homepageName", "homepageHref"]),
  },
};
</script>

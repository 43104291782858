<template>
  <div>
    <div class="d-flex justify-content-between">
      <p class="projects">{{ $t('priority_projects') }} </p>
      <a @click="$router.push('/projects')" class="view-all">{{ $t('view_all') }}</a>
    </div>
    <div class="divider"></div>
    <div v-for="project of ProjectPriority" :key="project._id">
      <div class="mt-4 d-flex justify-content-between">
        <div>
          <img src="@/assets/img/task.png" class="me-3">
          <span class="p-name">{{project.projectName}}</span>
        </div>
        <div>
          <p class="mb-0 due-date">{{ $t('due_date') }}</p>
          <span class="ms-1 date">{{project.endDates}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'PriorityProjects',
    props: {
      ProjectPriority: {
        type: Array,
        required: true
      }
    }
  }
</script>
<style scoped>

.projects {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #232360;
}

.view-all {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #FF814F;
  cursor: pointer;
}

.divider {
  height: 1px;
  background: #E8EDF1;
}

.due-date {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #232360;
}

.date {
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  color: #778399;
}

.p-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #232360;
}
</style>
<template>
  <div>
    <Multiselect
      v-model="value"
      max=1
      :options="options"
      :close-on-select="true"
      :searchable="true"
      :noResultsText="noResults"
      @select="setMultiSelect"
      @deselect="setMultiSelect"
      @clear="clearOptions"
      :track-by="label"
      :label="label"
      :valueProp="valueProp"
      :placeholder="placeholder"
    >
      <template
        ><strong>{{ option.projectName }}</strong></template
      >
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
export default {
  name: "VsudMultiSelectSingle",
  data() {
    return {
      value: this.defaultValue,
    };
  },
  components: {
    Multiselect,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
      require: true,
    },
    defaultValue: {
      type: Array,
      default: () => [],
      require: true,
    },
    label: {
      type: String,
      default: "projectName",
    },

    noResults:{
      type:String,
      default: "",
    },

    valueProp: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  methods: {
    setMultiSelect() {
console.log(this.value[0]);
      this.$emit("change-select", this.value);
    },

    clearOptions(){
      this.$emit("clear-options","Clear Options");
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>

</style>
<template>
  <div class="w-auto h-auto collapse navbar-collapse h-100" id="sidenav-collapse-main">
    <ul class="navbar-nav" @click="clearList()">
      <li class="nav-item">
        <sidenav-collapse :navText="$t('overview')" :to="{ name: 'AdminDashboard' }">
          <template #icon>
            <overview />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" >
        <sidenav-collapse :navText="$t('projects')" :to="{ name: 'Projects' }">
          <template #icon>
            <book />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse :navText="$t('employees')" :to="{ name: 'Employees' }">
          <template #icon>
            <user-octagon />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse :navText="$t('project_summary')" :to="{ name: 'Summary Project' }">
          <template #icon>
            <task-square />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse :navText="$t('detailed_project_report')" :to="{ name: 'Project Report' }">
          <template #icon>
            <task-square />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse :navText="$t('attendance_report')" :to="{ name: 'Attendance Report' }">
          <template #icon>
            <check-note />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="this.$store.state.origin!=this.$store.state.demoOrigin">
        <sidenav-collapse :navText="$t('admin_settings')" :to="{ name: 'AdminSettings' }">
          <template #icon>
            <settings-outline />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse :navText="$t('company_settings')" :to="{ name: 'CompSettings' }">
          <template #icon>
            <settings-outline />
          </template>
        </sidenav-collapse>
      </li>
       <li class="nav-item" >
        <sidenav-collapse :navText="$t('inactive_projects')" :to="{ name: 'Inactive Projects' }">
          <template #icon>
            <book />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse :navText="$t('inactive_employees')" :to="{ name: 'Employees Inactive' }">
          <template #icon>
            <user-octagon />
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item logout-button" @click="logout">
        <!-- <sidenav-collapse :navText="Logout" :to="{ name: 'Sign In' }">
          <template #icon>
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> <g> <path fill="none" d="M0 0h24v24H0z"/>
               <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2a9.985 9.985 0 0 1 8 4h-2.71a8 8 0 1 0 .001 12h2.71A9.985 9.985 0 0 1 12 22zm7-6v-3h-8v-2h8V8l5 4-5 4z"/> </g> </svg>
          </template>
          </sidenav-collapse> -->
        <div class="container">
          <div class="row">
            <div class="col-1 px-4">
              <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <g>
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2a9.985 9.985 0 0 1 8 4h-2.71a8 8 0 1 0 .001 12h2.71A9.985 9.985 0 0 1 12 22zm7-6v-3h-8v-2h8V8l5 4-5 4z" />
                </g>
              </svg>
            </div>
            <div class="col-9 px-3">{{ $t('logout') }}</div>
          </div>
        </div>
      </li>

    </ul>
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
// import Shop from "../../components/Icon/Shop.vue";
// import Office from "../../components/Icon/Office.vue";
// import Document from "../../components/Icon/Document.vue";
// import Settings from "../../components/Icon/Settings.vue";
import Book from "../../components/Icon/Book.vue";
import UserOctagon from "../../components/Icon/UserOctagon.vue";
import TaskSquare from "../../components/Icon/TaskSquare.vue";
import CheckNote from "../../components/Icon/CheckNote.vue";
import SettingsOutline from "../../components/Icon/SettingsOutline.vue";
import Overview from "../../components/Icon/Overview.vue";


export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "DailyTime",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },
  components: {
    SidenavCollapse,
    // Shop,
    // Office,
    // Document,
    // Settings,
    Book,
    UserOctagon,
    TaskSquare,
    CheckNote,
    SettingsOutline,
    Overview
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },

    clearList(){

localStorage.currentPage = 0;
localStorage.currentEmpPage = 0;
    },

    logout() {
      localStorage.isUserLoggedIn = "no"
      this.$router.push({
        path: `/sign-in`
      })
    },
  },
};
</script>
<style scoped>
.logout-button {
  position: relative;
  bottom: 2%;
  width: 20px;
  margin-top: 80px !important;
}

.logout-button:hover {
  cursor: pointer;
}
.navbar-nav .nav-link {
    display: flex;
    align-items: center;
    white-space: break-spaces !important;
  }
  .nav-link{
    padding-bottom: 0.5rem !important;
  }
</style>

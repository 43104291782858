<template>
  <div>
    <label class="form-control-label" v-if="hasLabel"><slot /> </label>
<Datepicker v-model="year" v-if="activeTabs === 'Overview'" year-picker placeholder="Choose Year" @closed="changeYear" @cleared="cleared"/>
    <Datepicker v-else
      v-model="date"
      :range="range"
      :multiCalendars="multiCalenders"
      :textInput="textInput"
      :multiDates="multiDates"
      format="dd-MM-yyyy"

      previewFormat="dd-MM-yyyy"
      :placeholder="placeholder"
      @closed="changeDate"
      @cleared="cleared"
    />

  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref, onMounted } from "vue";
export default {
  name: "VsudDatePicker",
  data() {
    return {
      today: new Date(),
    };
  },
  props: {
    range: {
      type: Boolean,
    },
    multiCalenders: {
      type: Boolean,
    },
    textInput: {
      type: Boolean,
    },
    multiDates: {
      type: Boolean,
    },
    startDate: {
      type: Date,
      default: () => new Date(),
    },
    endDate: {
      type: Date,
      default: () => new Date(new Date().setDate(new Date().getDate() + 7)),
    },
    hasLabel: {
      default: true,
    },
activeTabs:{
default:"activity",
},
yearS:{
default:"",
},
    placeholder: {
      default: "",
    }
  },
  components: {
    Datepicker,
  },
  setup(props) {
    const date = ref();
  

const year = ref(new Date().getFullYear());

  onMounted(() => {
const sDate = props.startDate;
      const eDate = props.endDate;
      date.value = [sDate, eDate];   
year.value=props.yearS; 
    });
    return {
      date,year
    };
  },
  methods: {
    changeDate() {
console.log(this.date);
if(this.date && this.date[0]!=""){
this.$emit("custom");
}
      this.$emit("date-range", JSON.stringify(this.date));
    },

    changeYear(){
this.$emit("year-range", JSON.stringify(this.year));
},
    cleared() {
      this.$emit('cleared')
    }
  },
};
</script>

<style scoped>
</style>
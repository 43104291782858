<template>
  <div class="row py-1 row-cols-auto">
    <h6 class="col mb-0 mt-2">{{ $t('show') }}</h6>
    <div class="col-1 px-0" :style="{ minWidth: '4rem', maxWidth: '4rem' }">
      <vsud-select
        label="show"
        :value="value"
        :labelRequire="false"
        :options="option"
        @change-select="selectRange"
      />
    </div>
    <h6 class="mb-0 col mt-2">{{ $t('entries') }}</h6>
  </div>
</template>

<script>
import VsudSelect from "../../components/VsudSelect.vue";
export default {
  name: "ShowEntries",
  props: {
    value: {
      type: String || Number,
    },
    option: {
      type: Array,
      default: () => [10, 15, 20, 25, 50],
    },
  },
  methods: {
    selectRange(e) {
      this.$emit("select-range", e);
    },
  },
  components: {
    VsudSelect,
  },
};
</script>

<style lang="css" scoped>
</style>
<template>
  <router-link class="nav-link" :to="to" v-bind="$attrs">
    <div
      class="
        text-center
        icon icon-shape icon-sm icon-white
        d-flex
        align-items-center
        justify-content-center
      "
      :class="this.$store.state.isRTL ? ' ms-2' : 'me-2'"
    >
      <slot name="icon"></slot>
    </div>
    <span
      class="nav-link-text"
      :class="this.$store.state.isRTL ? ' me-1' : 'ms-1'"
      >{{ navText }}</span
    >
  </router-link>
</template>
<script>
export default {
  name: "sidenav-collapse",
  props: {
    to: {
      type: [Object, String],
      required: true,
    },
    navText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
};
</script>
<style scoped>
.navbar-vertical .navbar-nav > .nav-item .nav-link.active {
  color: #f8f9fa;
  background-color: var(--bs-primary);
}
.navbar-vertical .navbar-nav > .nav-item .nav-link.active .icon {
  background-image: linear-gradient(310deg, #82d61600 0%, #82d61600 100%);
  color: #ffffff !important;
}
</style>

<template>
  <div :style="{ background: 'transparent' }">
    <div
      class="
        pt-md-6
        pb-md-2
        px-md-5
        card card-plain
        vh-100
        justify-content-around
      "
    >
      <div class="pb-0 text-start">
        <h2 class="mb-0 text-center">{{ $t('welcome_dashboard') }}</h2>
      </div>
      <div class="pb-0 text-start">
        <p class="px-md-5 mx-md-5 text-center">{{ $t('product_text') }}</p>
      </div>
      <div class="text-center mx-auto" style="width: 'min-content'">
        <img
          class="main-img shadow-lg rounded-3"
          src="@/assets/img/login-bg.png"
          alt="login-bg"
          style="width: 'inherit'"
        />
      </div>
      <div class="d-flex flex-row justify-content-between footer">
        <div>&copy; {{ $t('copyright') }}</div>
        <div>
          {{ $t('developed_by') }}
          <a href="https://www.webglobic.de/" target="_blank" class="webglobic"
            >WebGlobic Technologies GmbH</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.webglobic {
  color: #ff814f;
}
.main-img {
  height: 50vh;
}
</style>
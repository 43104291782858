import axios from "axios";

import { Config } from "../index";

const API = axios.create({baseURL: Config.baseUrl, withCredentials: true});
// const API = axios.create({ baseURL: "http://localhost:4040", 
// headers: {
//   Authorization: "Bearer 411|yK3VkktZDBXu5BkQVrc15p9rHZWVQacmsZ5zHSm"
// }});

//API.interceptors.request.use((req) => {
//    req.headers.authorization = "No Auth";
//    // req.headers.authorization = "Bearer 411|yK3VkktZDBXu5BkQVrc15p9rHZWVQacmsZ5zHSm";
//    // if (sessionStorage.getItem("token")) {
//    //   req.headers.authorization = `Bearer ${sessionStorage.getItem("token")}`;
//    // }
//    return req;
//});
//project
export const submitProject = (body) => API.post("/project", body);
export const getProject = () => API.get("/project");
export const getExistingProject = () => API.get("/project/true");
export const getInActiveProject = () => API.get("/project/inactive");
export const deleteProject = (id) => API.delete(`/project/${id}`);
export const checkActivityDeletable = (id) =>
    API.get(`/activity/canDelete/${id}`);
export const editProject = (id, body) => API.patch(`/project/${id}`, body);

//users

export const submitUser = (body) => API.post("/user", body);
export const getUsersList = () => API.get("/user");
export const getExistingUsers = () => API.get("/user/true");
export const getInactiveUsers = () => API.get("/user/inactive");
export const delUser = (id) => API.delete(`/user/${id}`);
export const editUser = (id, body) => API.patch(`/user/${id}`, body);

//dashboard
export const getDashboardData = (body) => API.get("/dashboard", body);

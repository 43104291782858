<template>
  <div class="form-group">
    <label :for="id">
      <slot />
    </label>
    <textarea
      :id="id"
      class="form-control"
      rows="5"
      :name="name"
      v-model="text"
      :placeholder="$t('your_text_here')"
      @input="handleInputChange"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: "VsudTextarea",
  data() {
    return {
      text: this.value,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Your text here...",
    },
    value: {
      type: String,
      default: "",
      required: true,
    },
  },
  methods: {
    handleInputChange(val) {
      this.$emit("handle-change", val);
    },
  },
};
</script>

import {
  submitProject,
  getProject,
  deleteProject,
  editProject,
  getUsersList,
  getExistingProject,
  getInActiveProject,
  checkActivityDeletable,
  getExistingUsers
} from "../../config/Apis/RestApis";
import { useToast } from "vue-toastification";

export default {
  state: {
    project: {
      projectName: "",
      projectManager: "",
      comments: "",
      projectVisibility: true,
      startDate: new Date(),
      endDate: new Date(new Date().setDate(new Date().getDate() + 7)),
      activities: [],
      users: [],
      hoursBooked: 0,
    },
    users: [],
    foundUser:{
      firstName: "",
      lastName: "",
      username: "",
      password: "",
      confirmPassword:"",
      phoneNumber: "",
      department: "",
      dateOfBirth: "",
    },
    existingUsers:[],
    addedActivity: {
      id: 0,
      activityName: "",
      totalHours: "",
      activityDescription: "",
    },
    projectList: [],
    existingProjects: [],
    inactiveProjects:[]
  },
  mutations: {
    setProject(state, payload) {
      state.project = payload.project;
    },

    handleSetNewProject(state, payload) {
      const { name, value, checked } = payload.target;
      state.project[name] = value;
      if (name === "projectVisibility") {
        state.project[name] = checked;
      }
    },
    dateRange(state, payload) {
      const parsedValue = JSON.parse(payload);
      state.project.startDate = parsedValue[0];
      state.project.endDate = parsedValue[1];
    },
    addActivity(state) {
      let length = state.project.activities.length;
      console.log(length)
      state.project.activities.push({
        id: length,
        activityName: "",
        totalHours: "",
        activityDescription: "",
      });
    },
    _deleteActivity(state, payload) {

      console.log("here")
      console.log(payload)

      let totalHours = state.project.hoursBooked

      console.log("Length",state.project.activities.length)
      
      

      state.project.activities.forEach(activity => {
        console.log(activity._id,payload._id)
        if(activity._id == payload._id){
          totalHours -= activity.totalHours
        }
      })

      state.project.hoursBooked = totalHours

      state.project.activities = state.project.activities.filter(             
        (activity) => activity.id !== payload.id
      ); 
      console.log(state.project.activities);
    },
    addActivityList(state, payload) {
      //console.log("ActivityList", payload)
      const { value, name } = payload.event.target;

      // state.addedActivity.id = payload.id
      // if(name === "totalHours"){
      //   state.addedActivity.totalHours = value
      // }
      // else if(name === "activityName"){
      //   state.addedActivity.activityName = value
      // }
      // else if(name === "activityDescription"){
      //   state.addedActivity.activityDescription = value
      // }

      // console.log(state.addedActivity)

      state.project.activities.forEach(activity => {
        if(activity.id == payload.id){
          if(name === "totalHours"){

            var _numStr = value.toString()
            if(_numStr.length > 4){
              _numStr = _numStr.substring(0, _numStr.length-1);
            }
            
              var _value = parseInt(_numStr)
              activity.totalHours = _value
          }
          else if(name === "activityName"){
           activity.activityName = value
          }
          else if(name === "activityDescription"){
           activity.activityDescription = value
          }
        }
      })


      // state.project.activities[payload.id][name] = value;

      //console.log(state.project.activities[payload.id])
      
      // state.project.hoursBooked = state.project.activities.reduce(
      //   (acc, cur) => +acc + + cur.totalHours,
      //   0
      // );

      let totalHours = 0
      state.project.activities.forEach(activity => {
        let parsedValue = parseInt(activity.totalHours)
        if(isNaN(parsedValue)){
          parsedValue = 0
          activity.totalHours = ""
        }
        totalHours += parseInt(parsedValue)
      })
      
      state.project.hoursBooked = totalHours

      // state.project.activities.forEach(activity => {
      //   if(activity.id === payload.id){
      //    state.project.hoursBooked = parseInt(state.project.hoursBooked) + parseInt(activity.totalHours)
      //   }
      // })

    },
    handleMultiSelect(state, payload) {
      const multiselect = JSON.parse(payload);
      console.log(
        "🚀 ~ file: project.js ~ line 64 ~ handleMultiSelect ~ multiselect",
        multiselect
      );
      state.project.users = multiselect;
    },
    resetProject(state) {
      state.project.startDate = new Date();
      state.project.endDate = new Date(
        new Date().setDate(new Date().getDate() + 7)
      );
      state.project.activities = [];
      state.project.users = [];
      state.project.hoursBooked = 0;
      state.project.projectName = "";
      state.project.projectManager = "";
      state.project.comments = "";
      state.project.projectVisibility = true;
      state.addedActivity.id = 0;
      state.addedActivity.activityName = "";
      state.addedActivity.totalHours = "";
    },
    findProjectById(state, payload) {
      // const found = state.projectList.find(
      //   (project) => project._id === payload
      // );
      // console.log(
      //   "🚀 ~ file: project.js ~ line 77 ~ findProjectById ~ found",
      //   JSON.parse(JSON.stringify(found))
      // );
      // state.project = JSON.parse(JSON.stringify(found));
      // console.log("Found Project", state.project)
      state.project = payload
      console.log("THe Project", state.project)
    },
  },
  actions: {
    updateValue({ commit }, payload) {
      commit("setProject", payload);
    },

    async deleteActivity({ commit }, payload) {
      if (payload._id) {
        const response = await checkActivityDeletable(payload._id);
        if (response.data.payload.canDelete) {
          commit("_deleteActivity", payload);
        } else {
          alert("Activity cannot be deleted as it is booked")
          return true
        }
      } else {
        commit("_deleteActivity", payload);
      }
    },

    async deleteActivity1({ commit }, payload) {
      commit("_deleteActivity", payload);
    },

    async handleSubmitProject({ state, commit }) {
      const { projectName, projectManager } = state.project;
      const toast = useToast();
      console.log([projectManager])
      if (projectName === "") {
        toast.warning('project name cannot be empty');
        return false;
      } 
      else if(projectName.length > 32){
        toast.warning("Project name should be less than 32 characters");
        return false;
      }
        else if(/^[a-zA-Z0-9- ]*$/.test(projectName) == false) {
        toast.warning("Project name should contain only characters");
        return false;
      }
      // else if (projectManager === "") {
      //   toast.warning("project manager cannot be empty");
      //   return false;
      // }
      else if(state.project.activities.length === 0){
        toast.warning('At least one activity should be added')
        return false;
      }
      else{
        let hasEmptyActivityName = false
        let activityNameTooLong = false
        state.project.activities.forEach(activity => {
          if(activity.activityName === ""){
            hasEmptyActivityName = true
          }
          else if(activity.activityName.length > 50){
            activityNameTooLong = true
          }
        })
        if(hasEmptyActivityName){
          toast.warning("Activity Name should not be empty")
          return false;
        }
        else if(activityNameTooLong){
          toast.warning("Activity Name should be less than 50 characters")
          return false;
        }
        // else if(!hasEmptyActivityName){
        //   const {projectName} = state.project
        //   let projectAlreadyPresent = false

        //   state.existingProjects.forEach(existingProject => {
        //     if(existingProject.projectName.trim() === projectName.trim() ){
        //       projectAlreadyPresent = true
        //       return
        //     }
        //   })
        //   if(projectAlreadyPresent){
        //     toast.warning("A Project with the same name already exists")
        //   }

        else if(!hasEmptyActivityName){
          const {projectName} = state.project
          // let projectAlreadyPresent = false

          const existingProject = state.existingProjects.find(existingProject =>
            existingProject.projectName.trim().toLowerCase() === projectName.trim().toLowerCase() &&
            existingProject._id !== state.project._id // Ensure we're not comparing with the current project (for editing)
          );

          if (existingProject) {
      toast.warning('A Project with the same name already exists');
      return false;
    }

          else {
            try {
              const res = await submitProject(state.project);
              if (!res.data.error) {
                commit("resetProject");
                return !res.data.error;
              } else {
                toast.warning("Project not uploaded");
                return false;
              }
            } catch (err) {
              toast.warning("Internal Server Error");
              console.log(
                "🚀 ~ file: project.js ~ line 123 ~ handleSubmitProject ~ err",
                err
              );
            }
          }

        }
        
      }
    },
    async handleEditProject({ state, commit }, id) {
      const { projectName, projectManager } = state.project;
      const toast = useToast();
      console.log([projectManager])
      if (projectName === "") {
        toast.warning(this.$t('project_name_cannot_be_empty'));
        return false;
      }
      //  else if (projectManager === "") {
      //   toast.warning("project manager cannot be empty");
      //   return false;
      // } 
      else if(state.project.activities.length === 0){
        toast.warning(this.$t('at_least_one_activity_should_be_added'))
      }
      else {
        try {
          const res = await editProject(id, state.project);
          commit("resetProject");
          return !res.data.error;
        } catch (err) {
          console.log(
            "🚀 ~ file: project.js ~ line 114 ~ handleEditProject ~ err",
            err
          );
        }
      }
  },
    async getProjectList({ state }) {
      try {
        const res = await getProject();
        console.log(
          "🚀 ~ file: project.js ~ line 134 ~ getProjectList ~ res",
          res.data.payload.projects
        );
        if (res?.data?.payload && res?.data?.payload.projects) {
          state.projectList = res.data.payload.projects;
        }
      } catch (err) {
        console.log(
          "🚀 ~ file: project.js ~ line 89 ~ getProjectList ~ err",
          err
        );
      }
    },
    async getExistingProjectAction({state}) {
      try {
        const res = await getExistingProject();
        console.log(
          "🚀 ~ file: project.js ~ line 134 ~ getProjectList ~ res",
          res.data.payload.projects
        );
        if (res?.data?.payload && res?.data?.payload.projects) {
          state.existingProjects = res.data.payload.projects;
        }
      } catch (err) {
        console.log(
          "🚀 ~ file: project.js ~ line 89 ~ getProjectList ~ err",
          err
        );
      }
    },
    async projectDelete({ dispatch }, payload) {
      const toast = useToast();
      try {
        const res = await deleteProject(payload);
        if (!res.data.error) {
          toast.success("Project Deleted Successfully")
          dispatch("getExisitingProjectAction");
          window.location.reload()
        }
      } catch (err) {
        console.log("🚀 ~ file: project.js ~ line 99 ~ deleteProj ~ err", err);
      }
    },
    async getUsers({ state }) {
      try {
        const {
          data: {
            payload: { users },
          },
        } = await getUsersList();
        const filteredUser = users.map((user) => {
          user.fullName = user.firstName + " " + user.lastName;
          return user;
        });
        state.users = filteredUser;
      } catch (err) {
        console.log("🚀 ~ file: project.js ~ line 104 ~ getUsers ~ err", err);
      }
    },

    async getNowExisitingUsers({ state }) {
      try {
        const {
          data: {
            payload: { users },
          },
        } = await getExistingUsers();
        const filteredUser = users.map((user) => {
          user.fullName = user.firstName + " " + user.lastName;
          return user;
        });
         state.existingUsers = filteredUser.sort((x, y) =>
        x.fullName.toLowerCase() < y.fullName.toLowerCase() ? -1 : 1
      );
//       state.existingUsers = filteredUser;    
       console.log("getExi",state.existingUsers)
      } catch (err) {
        console.log("🚀 ~ file: project.js ~ line 104 ~ getUsers ~ err", err);
      }
    },
    async getInActiveProjects({state}) {
      try {
        const res = await getInActiveProject();
        if (res?.data?.payload && res?.data?.payload.projects) {
          state.inactiveProjects = res.data.payload.projects;
        }
      } catch (err) {
        console.log(
          "🚀 ~ file: project.js ~ line 89 ~ getProjectList ~ err",
          err
        );
      }
    }

  },
};

import {
  getDashboardData,
} from "../../config/Apis/RestApis";

export default {
  state: {
    admin: {
      email: "",
      password: "",
      compName: ''
    },
    dashboardData: {
      totalProjects: '',
      companySettings: {},
      teamMembers: [],
      projectPriority: [],
      projectList: [],
    }
  },
  mutations: {
    handleAdminData(state, payload) {
      const { name, value } = payload.target;
      state.admin[name] = value;
    },

    SET_DASHBOARD_DATA(state, payload) {
      state.dashboardData = payload
    }
  },
  actions: {
    updateValue({ commit }, payload) {
      commit("updateValue", payload);
    },

    async fetchDashboardData({ commit }) {
      try {
        const res = await getDashboardData();
//        console.log(
//          "🚀 ~ file: admin.js ~ line 28 ~ getDashboardData ~ res",
//          res.data.payload
//        );
        if (res?.data?.payload) {
          commit('SET_DASHBOARD_DATA', res.data.payload)
        }
      } catch (err) {
//        console.log(
//          "🚀 ~ file: admin.js ~ line 36 ~ getDashboardData ~ err",
//          err
//        );
      }
    }
  },

  getters: {
    dashboardData: state => state.dashboardData
  }
};

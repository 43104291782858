// import { createI18n } from 'vue-i18n'
// import en from './locales/en.json'
// import de from './locales/de.json'

// function loadLocaleMessages() {
//   const locales = [{ en: en }, { de: de }]
//   const messages = {}
//   locales.forEach(lang => {
//     const key = Object.keys(lang)
//     messages[key] = lang[key] 
//   })
//   return messages
// }

// export default createI18n({
//   locale: 'de',
//   fallbackLocale: 'de',
//   messages: loadLocaleMessages()
// })

import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import de from './locales/de.json';
import axios from "axios";

function loadLocaleMessages() {
  const locales = [{ en: en }, { de: de }]
  const messages = {}
  locales.forEach(lang => {
    const key = Object.keys(lang)
    messages[key] = lang[key] 
  })
  return messages
}
async function setupI18n() {
  const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/settings`, {
    withCredentials: true,
  });

  let userLanguage = 'en'; // Default fallback language
  if (response.data && response.data.payload && response.data.payload.settings) {
    const user = response.data.payload.settings;
    userLanguage = user.language;
  }
  console.log(userLanguage)
  return createI18n({
    locale: userLanguage,
    fallbackLocale: userLanguage,
    messages: loadLocaleMessages(),
  });
}

export default setupI18n;
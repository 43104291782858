<template>
  <div class="example">
    <apexcharts width="80%" :height="height" type="bar" :options="chartOptions" :series="series"></apexcharts>
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'
export default {
  name: 'Chart',
  props: {
    ChartDashboard: {
      type: Array,
      required: true
    }
  },
  components: {
    apexcharts: VueApexCharts,
  },
  data: function () {
    return {
      height: 'auto',

      series: [{
        name: this.$t('booked_hours'),
        data: [],
      }],
      chartOptions: {
        colors: ['#ff814f'],
        grid: {
          show: true,
          borderColor: '#90A4AE',
          strokeDashArray: 0,
          position: 'back',
          xaxis: {
            lines: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: false
            }
          },
          row: {
            colors: undefined,
            opacity: 0.5
          },
          column: {
            colors: undefined,
            opacity: 0.5
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
        },
        chart: {
          type: 'bar',
          offsetX: 0
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            barHeight: '50%',
          },
          dataLabels: {
            enabled: true,
            style: {
              colors: ['#333']
            },
            offsetX: 30
          },

        },

        xaxis: {
          type: 'category',
          axisBorder: {
            offsetY: 0
          }
        }
      }
    }
  },

  beforeMount: function () {
    console.log("Chart Mounted");
    var valData = [];
    for (var dt of this.ChartDashboard) {
      var name = "";
      if (dt.projectName) {
        name = dt.projectName;
      } else if (dt.activityName) {
        name = dt.activityName;
      }
 else if (dt.monthName) {
        name = this.$t(dt.monthName);
      }
 else {
        name = dt.firstName + " " + dt.lastName;
      }
      var dt1 = { x: name, y: dt.bookedHours };
      valData.push(dt1);
    }
    if (valData.length <= 5) {
      this.height = '300px';
    } else if (valData.length > 5 && valData.length <= 8) {
      this.height = '500';
    }
    else {
      this.height = '700';
    }
    this.series[0].data = valData;
  }
}
</script>

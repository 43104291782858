<template>
  <div class="position-fixed cs_end p-3 opacity-100" style="z-index: 11">
    <div id="liveToast" class="toast bg-warning opacity=100" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header">
        <strong class="me-auto">{{ $t('error') }}</strong>
        <button type="button" class="btn-close bg-primary" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body">
        {{ errorMessage }}
      </div>
    </div>
  </div>

  <div class="container mt-5 ">
    <div class="row flex-nowrap">
      <div class="col-1" style="width:40px">
        <img class="back_arrow_cs" src="../assets/img/back_arrow.png" @click="close_resource" />
      </div>
      <div class="col-3 project-name-cs">
        {{ projects.projectName }}
      </div>

    </div>
  </div>

  <div class="container mt-4 mx-2 mx-auto">
    <div class="row">
      <div class="col-3 p-3 card cs_card mb-3" style="background: #fff; border-radius: 16px;">
        <div class="container">
          <div class="col-12">
            <div>{{ $t('total_hours') }}</div>
            <div class="divider mt-2"></div>
          </div>
          <div class="col-12 mt-2 mb-2">
            <div class="row">
              <div class="col-9">{{ $t('estimated') }}</div>
              <div class="col-3">
                <div>{{ pro_estimatedHours }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3 p-3 card  mb-3 cs_card" style="background: #fff; border-radius: 16px;">
        <div class="container">
          <div class="col-12">
            <div>{{ $t('total_hours') }}</div>
            <div class="divider mt-2"></div>
          </div>
          <div class="col-12 mt-2 mb-2">
            <div class="row">
              <div class="col-9">{{ $t('booked') }}</div>
              <div class="col-3">
                <div>{{ pro_bookedHours }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3 p-3 card  mb-3 cs_card" style="background: #fff; border-radius: 16px;">
        <div class="container">
          <div class="col-12">
            <div>{{ $t('no_employees') }}</div>
            <div class="divider mt-2"></div>
          </div>
          <div class="col-12 mt-2 mb-2">
            <div class="row">
              <div class="col-9">{{ $t('assigned') }}</div>
              <div class="col-3">
                <div>{{ pro_employeesCount }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-3 p-3 card  mb-3 cs_card" style="background: #fff; border-radius: 16px;">
        <div class="container">
          <div class="col-12">
            <div>{{ $t('most_booked_activity') }}</div>
            <div class="divider mt-2"></div>
          </div>
          <div class="col-12 mt-2 mb-2">
            <div class="row">
              <div class="col-12">
                <div>{{ pro_mostBookedActivity }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <!--Here-->
      <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteModalLabel">{{ $t('confirmation') }}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              {{ $t('delete_confirmation') }}?
              <br />
              {{ $t('deleted_undone') }}!
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                {{ $t('no') }}
              </button>
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="deleteUser(toDelete)">
                {{ $t('yes') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--Close-->

      <!-- Tabs to switch -->

      <div class="container ms-n3 mt-2 mb-2 p-2">
        <div class="row flex-nowrap m-2">
         <div class="col-3 tab_container">
            <div class="tab" @click="setCurrentTab('Overview')">{{ $t('overview') }}</div>
            <div class="tab_divider" v-if="activeTab === 'Overview'"></div>
          </div>
          <div class="col-3 tab_container">
            <div class="tab" @click="setCurrentTab('Activity')">{{ $t('activity') }}</div>
            <div class="tab_divider" v-if="activeTab === 'Activity'"></div>
          </div>
          <div class="col-3 tab_container">
            <div class="tab" @click="setCurrentTab('Resources')">Resources</div>
            <div class="tab_divider" v-if="activeTab === 'Resources'"></div>
          </div>
          <div class="col-md-3 text-end" style="margin-left: 17rem;">
            <vsud-button color="primary" variant="gradient" @click="goToNewSheet">
              {{ $t('download_report') }}
            </vsud-button>
          </div>
          <div class="col-3">
            <VsudDatePicker :textInput="true" :range="true" @date-range="dateRange" @year-range="yearRange" :has-label="false" :start-date="''" :yearS=year
              :end-date="''" :key="cusDate" format="DD-MM-yyyy H i s" :active-tabs=activeTab @cleared="dateCleared" :placeholder="$t('all_time_period')">
            </VsudDatePicker>
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-end mb-3">

      </div>

      <!-- End of tabs to switch -->

<!-- Overview --->

<div class="mx-auto col-12 flex-column" v-if="activeTab === 'Overview'">
        <ul class="nav nav-tabs" id="myTab2" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="olist-tab" data-bs-toggle="tab" data-bs-target="#olist" type="button"
              role="tab" aria-controls="olist" aria-selected="true">
              <img src="../assets/img/list.png" class="list" width="15" height="15">
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="ograph-tab" data-bs-toggle="tab" data-bs-target="#ograph" type="button"
              role="tab" aria-controls="ograph" aria-selected="false">
              <img src="../assets/img/bar-chart.png" class="bar-chart" width="15" height="15">
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent2">
          <div class="tab-pane fade show active" id="olist" role="tabpanel" aria-labelledby="olist-tab">


            <div class="mt-4 card card-plain">
              <div class="card mb-4">
                <div class="card-header pb-0 p-3">
                  <div class="row">
                    <div class="col-6 d-flex align-items-center">
                      <h6 class="mb-0">{{ $t('project_overview') }}</h6>
                    </div>                    
                  </div>
                </div>
                <div class="card-body px-0 pt-0 pb-2 mt-2">
                  <div class="table-responsive p-0">
                    <table class="table align-items-center justify-content-center mb-0">
                      <thead>
                        <tr>
                          <th>{{ $t('month_name') }}</th>                          
                          <th class="text-center">{{ $t('hours_booked') }}</th>
                          <th class="text-center">
                            <div>
                              {{ $t('project_contribution') }}
                              <!-- <br />
                              (Contribution) -->
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="totalOverview === undefined ? true : (totalOverview.length > 0 ? false : true)">
                        <tr>
                          <td></td>
                          <td class="text-center">
                            <div style="text-align: end;">{{ $t('no_data_found') }}</div>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr class="table-row" v-for="(overview, index) in totalOverview" :key="index">
                          <td class="text-center">
                            <div class="d-flex px-2">
                              <div class="my-auto">
                                <h6 class="mb-0 text-sm">
                                  {{ overview.monthName === undefined ? '' : $t(overview.monthName) }}
                                </h6>
                              </div>
                            </div>
                          </td>
                          <td class="text-center">
                            {{ overview.bookedHours === undefined ? '' : overview.bookedHours }}
                          </td>
                          <td class="text-center">
                            {{ overview.completedPercentage === undefined ? '' : `${overview.completedPercentage}%` }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="totalOverview === undefined ? false : (totalOverview.length > 0 ? true : false)" class="tab-pane fade" id="ograph" role="tabpanel"
            aria-labelledby="ograph-tab">
            <chart :ChartDashboard="totalOverview" :key="componentKey" />
          </div>
        </div>


      </div>

<!-- Overview End --->



      <!-- Activity Table -->

      <div class="mx-auto col-12 flex-column" v-if="activeTab === 'Activity'">
        <ul class="nav nav-tabs" id="myTab1" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="alist-tab" data-bs-toggle="tab" data-bs-target="#alist" type="button"
              role="tab" aria-controls="alist" aria-selected="true">
              <img src="../assets/img/list.png" class="list" width="15" height="15">
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="agraph-tab" data-bs-toggle="tab" data-bs-target="#agraph" type="button"
              role="tab" aria-controls="agraph" aria-selected="false">
              <img src="../assets/img/bar-chart.png" class="bar-chart" width="15" height="15">
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent1">
          <div class="tab-pane fade show active" id="alist" role="tabpanel" aria-labelledby="alist-tab">


            <div class="mt-4 card card-plain">
              <div class="card mb-4">
                <div class="card-header pb-0 p-3">
                  <div class="row">
                    <div class="col-6 d-flex align-items-center">
                      <h6 class="mb-0">Activities Overview</h6>
                    </div>
                    <div class="col-6 text-end" v-if="false">
                      <vsud-button @click="goToTimesheet">
                        <i class="fas fa-plus me-2"></i>
                        {{ $t('add_new_record') }}
                      </vsud-button>
                    </div>
                  </div>
                </div>
                <div class="card-body px-0 pt-0 pb-2 mt-2">
                  <div class="table-responsive p-0">
                    <table class="table align-items-center justify-content-center mb-0">
                      <thead>
                        <tr>
                          <th>{{ $t('activity_name') }}</th>
                          <th class="text-center">{{ $t('hours_estimated') }}</th>
                          <th class="text-center">{{ $t('hours_booked') }}</th>
                          <th class="text-center">
                            <div>
                              {{ $t('project_contribution') }}
                              <!-- <br />
                              (Contribution) -->
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="totalActivities === undefined ? true : (totalActivities.length > 0 ? false : true)">
                        <tr>
                          <td></td>
                          <td class="text-center">
                            <div style="text-align: end;">{{ $t('no_data_found') }}</div>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr class="table-row" v-for="(activity, index) in totalActivities" :key="index">
                          <td class="text-center">
                            <div class="d-flex px-2">
                              <div class="my-auto">
                                <h6 class="mb-0 text-sm">
                                  {{ activity.activityName === undefined ? '' : activity.activityName }}
                                </h6>
                              </div>
                            </div>
                          </td>
                          <td class="text-center">
                            {{ activity.estimatedHours === undefined ? '' : activity.estimatedHours }}
                          </td>
                          <td class="text-center">
                            {{ activity.bookedHours === undefined ? '' : activity.bookedHours }}
                          </td>
                          <td class="text-center">
                            {{ activity.completedPercentage === undefined ? '' : `${activity.completedPercentage}%` }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="totalActivities.length > 0" class="tab-pane fade" id="agraph" role="tabpanel"
            aria-labelledby="agraph-tab">
            <chart :ChartDashboard="totalActivities" :key="componentKey" />
          </div>
        </div>


      </div>

      <!-- User table  -->
      <div class="mx-auto col-12 flex-column" v-if="activeTab === 'Resources'">

        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="list-tab" data-bs-toggle="tab" data-bs-target="#list" type="button"
              role="tab" aria-controls="home" aria-selected="true">
              <img src="../assets/img/list.png" class="list" width="15" height="15">
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="graph-tab" data-bs-toggle="tab" data-bs-target="#graph" type="button" role="tab"
              aria-controls="profile" aria-selected="false">
              <img src="../assets/img/bar-chart.png" class="bar-chart" width="15" height="15">
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="list" role="tabpanel" aria-labelledby="list-tab">
            <div class="mt-4 card card-plain">
              <div class="card mb-4">
                <div class="card-header pb-0 p-3">
                  <div class="row">
                    <div class="col-6 d-flex align-items-center">
                      <h6 class="mb-0">{{ $t('resources_overview') }}</h6>
                    </div>
                    <div class="col-6 text-end" v-if="false">
                      <vsud-button @click="goToTimesheet">
                        <i class="fas fa-plus me-2"></i>
                        {{ $t('add_new_record') }}
                      </vsud-button>
                    </div>
                  </div>
                </div>
                <div class="card-body px-0 pt-0 pb-2 mt-2">
                  <div class="table-responsive p-0">
                    <table class="table align-items-center justify-content-center mb-0">
                      <thead>
                        <tr>
                          <th>{{ $t('resource_name') }}</th>
                          <th class="text-center">{{ $t('email') }}</th>
                          <th class="text-center">Dept/Role</th>
                          <th class="text-center">{{ $t('hours_booked') }}</th>
                          <th class="text-center">
                            <div>
                              {{ $t('project_contribution') }}
                            </div>
                          </th>
                          <th class="text-center">{{ $t('status') }}</th>
                        </tr>
                      </thead>
                      <tbody v-if="totalUsers === undefined ? true : (totalUsers.length > 0 ? false : true)">
                        <tr>
                          <td></td>
                          <td></td>
                          <td class="text-center">
                            <div style="text-align: end;">{{ $t('no_data_found') }}</div>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr class="table-row" v-for="(user, index) in totalUsers" :key="index">
                          <td class="text-center">
                            <div class="d-flex px-2">
                              <div class="my-auto">
                                <h6 class="mb-0 text-sm">
                                  {{ `${user.firstName} ${user.lastName}` }}
                                </h6>
                              </div>
                            </div>
                          </td>
                          <td class="text-center">
                            {{ user.username }}
                          </td>
                          <td class="text-center">
                            {{ user.department }}
                          </td>
                          <td class="text-center">
                            {{ user.bookedHours }}
                          </td>
                          <td class="text-center">
                            {{ `${user.completedPercentage}%` }}
                          </td>
                          <td class="text-center">
                            {{ user.status }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="totalUsers.length > 0" class="tab-pane fade" id="graph" role="tabpanel" aria-labelledby="graph-tab">
            <chart :ChartDashboard="totalUsers" :key="componentKey" />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VsudButton from "@/components/VsudButton.vue";
import { mapMutations } from "vuex";
// import AddTimesheet from "./AddTimesheet.vue";
import Chart from './components/AdminDashboard/ChartAdminDashboard.vue';
import VsudDatePicker from "../components/VsudDatePicker.vue";

import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "project-resources",
  components: {
    VsudButton,
    // AddTimesheet,
    VsudDatePicker,
    Chart
  },
  data() {
    return {
      projects: {
        "estimatedHours": 0,
        "bookedHours": 0,
        "employeesCount": 0,
        "mostBookedActivity": "",
        "overview":[],
        "activities": [],
        "users": [],
      },
      overview:[],
      activeTab: "Overview",
      startDate: "",
      endDate: "",
      componentKey: 0,
      csvHeader:
        "",
      csvContent: "",
      pro_estimatedHours: 0,
      pro_bookedHours: 0,
      pro_employeesCount: 0,
      pro_mostBookedActivity: "",
year:new Date().getFullYear(),
cusDate:0,
    };
  },

  beforeMount() {
    this.$store.state.showSidenav = true;
    this.$store.state.showNavbar = true;
  },
  mounted() {
    this.$store.state.showSidenav = true;
    this.$store.state.showNavbar = true;

    console.log("params", this.$store.state.projectNameResource)

    this.setHomeHeader("Projects");
    this.setHomeHref("/Projects");
  
this.yearRange(this.year);
    this.forceRerender();
  },

  created() {
    var _isUserLoggedIn = localStorage.isUserLoggedIn;
    if (_isUserLoggedIn === undefined) {
      localStorage.isUserLoggedIn = "no";
      _isUserLoggedIn = localStorage.isUserLoggedIn;
    }
    if (_isUserLoggedIn === "no") {
      this.$router.push({
        path: `/sign-in`,
      });
      return;
    }
  },
  watch: {
    totalUsers() {
      return this.projects["users"]
    },

totalOverview(){
 return this.overview
},

    totalActivities() {
      return this.projects["activities"]
    },

  },

  methods: {
    ...mapMutations([
      "setHomeHeader",
      "setHomeHref",

    ]),
    showOptions() { },
    close_resource() {
      window.history.back();
    },
forceRerender() {
      this.cusDate += 1;
    },
    fetchData() {
      axios
        .get(`${process.env.VUE_APP_BASE_URL}/project/summary/${this.$route.params.id}?startDate=${this.startDate}&endDate=${this.endDate}`, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data) {
            if (res.data && res.data.payload && res.data.payload.projects) {
              this.projects = res.data.payload.projects;
              if (this.startDate == '') {
                this.pro_estimatedHours = res.data.payload.projects["estimatedHours"];
                this.pro_bookedHours = res.data.payload.projects["bookedHours"];
                this.pro_employeesCount = res.data.payload.projects["employeesCount"];
                this.pro_mostBookedActivity = res.data.payload.projects["mostBookedActivity"];
              }
              this.componentKey += 1;
              this.$emit("chart-dashboard", res.data.payload.projects['activities']);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setCurrentTab(currentTab) {
      this.activeTab = currentTab;
if(currentTab!="Overview"){
this.fetchData();
}else{
this.yearRange(this.year);
}
if (this.startDate != '') {
this.dateRange(JSON.stringify([this.startDate.toISOString(),this.endDate.toISOString()]));
}
this.forceRerender();
    },
    dateRange(payload) {
      const parsedValue = JSON.parse(payload);
      if (parsedValue[0] && parsedValue[1]) {
        var start = new Date(parsedValue[0]);
        var end = new Date(parsedValue[1]);
        this.startDate = "" + start.getFullYear() + '-' + ((start.getMonth() + 1) > 9 ? '' : '0') + (start.getMonth() + 1) + '-' + (start.getDate() > 9 ? '' : '0') + start.getDate();
        this.endDate = "" + end.getFullYear() + '-' + ((end.getMonth() + 1) > 9 ? '' : '0') + (end.getMonth() + 1) + '-' + (end.getDate() > 9 ? '' : '0') + end.getDate();
        console.log(this.startDate, this.endDate)
        this.fetchData();
      }
    },

yearRange(payload){
this.fetchData();
this.year=payload;
axios
        .get(`${process.env.VUE_APP_BASE_URL}/project/overview/${this.$route.params.id}?year=${payload}`, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data) {
            if (res.data && res.data.payload) {
              this.overview = res.data.payload.projects;
              this.componentKey += 2;
              this.$emit("chart-dashboard", res.data.payload.projects)
            }
          }

        })
        .catch((error) => {
          console.log(error);
        });
},
    dateCleared() {
if(this.activeTab === "Overview"){
this.year="";
this.yearRange("");
}else{
 this.startDate = "";
      this.endDate = ""; 
this.fetchData();
}
    },
    async goToNewSheet() {
      const tab = this.activeTab;
      var csv_name = '';
      var report_time = 'All';
      const today_date = new Date();

      let day = today_date.getDate();
      let month = today_date.getMonth() + 1;
      let year = today_date.getFullYear();
      let hours = (today_date.getHours() < 10 ? '0' : '') + today_date.getHours();
      let mins = (today_date.getMinutes() < 10 ? '0' : '') + today_date.getMinutes();
      let secs = (today_date.getSeconds() < 10 ? '0' : '') + today_date.getSeconds();
      let today = day + '-' + month + '-' + year + 'T' + hours + 'H-' + mins + 'm-' + secs + 's';
      if (this.startDate != '') {
        var date = new Date(this.startDate);
        console.log(date.toLocaleString(), this.startDate)
        var result = "" + (date.getDate() > 9 ? '' : '0') + date.getDate() + '-' + ((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1) + '-' + date.getFullYear();
        var date1 = new Date(this.endDate);
        var result1 = "" + (date1.getDate() > 9 ? '' : '0') + date1.getDate() + '-' + ((date1.getMonth() + 1) > 9 ? '' : '0') + (date1.getMonth() + 1) + '-' + date1.getFullYear();
        report_time = result + ' To ' + result1;
      }

if (tab == 'Overview') {
report_time=this.year;
        this.csvContent = "data:text/csv;charset=utf-8,Project Name:," + this.projects.projectName + ",\nReport Period:," + report_time + ",\n\nMonth,Hours Booked,Project Contribution\n";
        this.totalOverview.forEach((record) => {
          this.csvContent += `${record.monthName},${record.bookedHours},${record.completedPercentage}\n`;
        });
        csv_name = 'Overview-Report-'+this.projects.projectName+'-' + today + '.csv';
      }

      if (tab == 'Activity') {
        this.csvContent = "data:text/csv;charset=utf-8,Project Name:," + this.projects.projectName + ",\nReport Period:," + report_time + ",\n\nActivity,Hours Estimated,Hours Booked,Project Contribution\n";
        this.totalActivities.forEach((record) => {
          this.csvContent += `${record.activityName},${record.estimatedHours},${record.bookedHours},${record.completedPercentage}\n`;
        });
        csv_name = 'Activity-Report-' + today + '.csv';
      }
      if (tab == 'Resources') {
        this.csvContent = "data:text/csv;charset=utf-8,Project Name:," + this.projects.projectName + ",\nReport Period:," + report_time + ",\n\nResource,Email,Dept/Role,Hours Booked,Project Contribution,Status\n";
        this.totalUsers.forEach((record) => {
          let temp = record.department.replace(/[',',]+/g, " /");
          this.csvContent += `${record.firstName + record.lastName},${record.username},${temp},${record.bookedHours},${record.completedPercentage},${record.status}\n`;
        });
        csv_name = 'Resources-Report-' + today + '.csv';
      }
      const encodedUri = encodeURI(this.csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", csv_name);
      document.body.appendChild(link);

      link.click();
    },
  },

  computed: {
    totalEstimatedHours() {
      console.log("EH", this.projects["estimatedHours"])
      return this.projects["estimatedHours"]
    },

    totalBookedHours() {
      return this.projects["bookedHours"]
    },

    totalEmployeesCount() {
      return this.projects["employeesCount"]
    },

    mostBookedActivity() {
      return this.projects["mostBookedActivity"]
    },

    totalActivities() {
      const temp = this.projects["activities"];
      console.log(temp)
      // if (this.startDate && this.endDate) {
      //   reports = reports.filter((report) => {
      //     const startDate = new Date(this.startDate).setHours(0, 0, 0, 0);
      //     const endDate = new Date(this.endDate).setHours(23, 59, 59, 999);
      //     const reportTime = new Date(report.date).getTime();
      //     console.log(startDate, endDate, reportTime);
      //     if (reportTime >= startDate && reportTime <= endDate) {
      //       return true;
      //     } else {
      //       false;
      //     }
      //   });
      // }
      return this.projects["activities"]
    },

    totalUsers() {
      return this.projects["users"]
    },

    totalOverview(){
        return this.overview
     },

    currentWeek() {
      const currentDate = new Date();
      const currentDay = currentDate.getDay();
      const sunDiff = currentDate.getDate() - currentDay + 1;
      const satDiff = currentDate.getDate() + 6 - currentDay + 1;
      return [
        new Date(new Date(currentDate.setDate(sunDiff)).setHours(0, 0, 0, 0)),
        new Date(new Date(currentDate.setDate(satDiff)).setHours(0, 0, 0, 0)),
      ];
    },

  }
};
</script>

<style scoped>
.cs_width {
  padding-left: 280px !important;
}

.divider {
  height: 1px;
  background: #E8EDF1;
}

.table-row {
  /* cursor: pointer; */
}

.ts-status-draft div {
  width: 100%;
  padding: 5px;
  background-color: rgba(255, 129, 79, 0.2);
  border-radius: 30px;
  color: rgba(255, 129, 79, 1);
}

.ts-status-submitted div {
  width: 100%;
  padding: 5px;
  background-color: rgba(229, 246, 239, 1);
  border-radius: 30px;
  color: rgba(56, 203, 137, 1);
}

.cs_img {
  height: 16px;
  width: 16px;
}

.cs_end {
  right: 0;
  top: 0;
}

.cs_card {
  width: 300px !important;
  margin-right: 20px !important;
}

.tab_container {
  width: 120px !important;
}

.tab {
  position: relative;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}

.tab_divider {
  height: 2px;
  background: #ff814f;
}

.back_arrow_cs {
  cursor: pointer;
  height: 16px !important;
  width: 16px !important;
}

.project-name-cs {
  font-size: 18px;
  font-weight: 600;
  /* color:#ff814f; */
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
</style>
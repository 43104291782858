<template>
  <div class="container">
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">{{ $t('confirmation') }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">{{ $t('delete_confirmation') }}</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              {{ $t('no') }}
            </button>
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="projectDelete(toDelete)">
              {{ $t('yes') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4 p-3 px-4">
          <div class="card-header pb-0 p-3">
            <div class="row">
              <div class="col-6 d-flex align-items-center text-muted fw-bolder">
                {{ $t('projects') }}
              </div>
              <div class="col-6 text-end">
                <vsud-button color="primary" variant="gradient" @click="goToNewSheet">
                  <i class="fas fa-plus me-2"></i>
                  {{ $t('add_new_project') }}
                </vsud-button>
              </div>
            </div>
            <vsud-horzntl />
            <div class="row d-flex justify-content-between">
              <div class="col-3">
                <show-entries :value="query.limit" @select-range="selectRange" />
              </div>
              <div class="col-6">
                <vsud-multi-select class="multi_select_customize" @set-select="handleMultiSelectProject"
                  :options="getExistingProjects" :placeholder="$t('search_by_project_name')" label="projectName"
                  valueProp="projectName" @clear-options="clearProjects" :noResults="noResultsTextProjects" />
              </div>
            </div>
          </div>
          <div class="card rounded-2 mb-4">
            <div class="card-body px-0 pt-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0" data-pagination="true" data-id-field="name"
                  data-page-list="[5, 10, 25, 50, 100, ALL]" data-page-size="5">
                  <thead :style="{ background: '#FAFBFC' }">
                    <tr>
                      <th v-for="header of tableHeaders" :key="header" :class="header.name === 'Project name' ||
                        header.name === 'number of members' ||
                        header.name === 'Estimated Hours' ||
                        header.name === 'Booked Hours'
                        ? `fw-bolder text-uppercase text-xs text-dark sorting
                        `
                        : `text-uppercase fw-bolder text-dark text-xs`
                        " class="fw-bold py-3 " @click="sortList(header.key_name)">
                        {{ header.name }}<i v-if="header.name === 'Project name' ||
                          header.name === 'number of members' ||
                          header.name === 'Estimated Hours'" :class="sortIcon(header)" class="sort_icon"></i>
                      </th>
                    </tr>
                  </thead>
                  <!-- <div class="d-flex justify-content-center">
                  <p class="display-6 align-items-center">
                    No projects to load
                  </p>
                </div> -->

                  <tbody>
                    <!-- {{getProjectLists.slice(query.limit * query.page, (query.limit * query.page) + query.limit ).map((ts, index) => {})}} -->
                    <tr class="table-row" v-for="ts in listingData.slice(
                      query.limit * query.page,
                      query.limit * query.page + query.limit
                    )" v-bind:key="ts._id">
                      <td @click="goToResources(ts._id, ts.projectName)">
                        <div class="d-flex px-2 py-1">
                          <div>
                            <vsud-avatar :img="img1" size="sm" border-radius="lg" class="me-3" alt="user1" />
                          </div>
                          <div class="d-flex flex-column justify-content-center">
                            <p class="mb-0 fw-bold text-xs">
                              {{ ts.projectName }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td @click="goToResources(ts._id, ts.projectName)">
                        <div class="d-flex justify-content-center px-2 py-1">
                          <div class="d-flex flex-column justify-content-between">
                            <p class="mb-0 fw-bold text-xs">
                              {{ ts.users.length }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td @click="goToResources(ts._id, ts.projectName)">
                        <div class="d-flex justify-content-center px-2 py-1">
                          <div class="d-flex flex-column justify-content-center">
                            <p class="mb-0 fw-bold text-xs">
                              {{ ts.hoursBooked }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td @click="goToResources(ts._id, ts.projectName)">
                        <div class="d-flex justify-content-center px-2 py-1">
                          <div class="d-flex flex-column justify-content-center">
                            <p class="mb-0 fw-bold text-xs">
                              {{
                                timesheet.find(
                                  (i) => i._id && i._id === ts.projectName
                                )
                                  ? timesheet.find(
                                    (i) => i._id === ts.projectName
                                  ).allHours
                                  : 0
                              }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td @click="goToResources(ts._id, ts.projectName)">
                        <div class="d-flex px-2 py-1">
                          <div class="d-flex flex-column justify-content-center">
                            <p class="mb-0 fw-bold text-xs">
                              {{ switchDate(ts.startDate) }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td @click="goToResources(ts._id, ts.projectName)">
                        <div class="d-flex px-2 py-1">
                          <div class="d-flex flex-column justify-content-center">
                            <p class="mb-0 fw-bold text-xs">
                              {{ switchDate(ts.endDate) }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <!-- <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{
                          ts.users
                            .map((u) => `${u.firstName} ${u.lastName}`)
                            .join(",")
                        }}
                      </p>
                    </td> -->
                      <td>
                        <a class="btn btn-link text-success text-gradient px-3 mb-0" @click="goToEditSheet(ts._id)">
                          <i class="fas fa-pencil-alt text-success me-2" aria-hidden="true"></i>
                        </a>
                        <a class="btn btn-link text-success text-gradient px-3 mb-0" @click="copyProject(ts._id)">
                          <i class="fas fa-copy text-success me-2" aria-hidden="true"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="my-auto pt-3 rounded-2" :style="{ background: '#FAFBFC' }">
                  <pagination :totalCount="listingData.length" :skip="query.page" :limit="query.limit"
                    @update-page="updatePage" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";
import VsudHorzntl from "../components/VsudHorzntl.vue";
import axios from "axios";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { dateFromIso, convertIST } from "../utils/helper";
import Pagination from "./components/Pagination.vue";
import ShowEntries from "./components/ShowEntries.vue";
import VsudMultiSelect from "../components/VsudMultiSelect.vue";

export default {
  name: "timesheet",
  data() {
    return {
      listingData: [],
      projectASC: true,
      hoursBookedASC: true,
      membersASC: true,
      BookedHrsASC: true,
      timesheets: [],
      timesheet: [],
      toDelete: "",
      query: {
        limit: 10,
        page: localStorage.currentPage ? parseInt(localStorage.currentPage) : 0,
      },
      selectedProjects: [],
      noResultsTextProjects: this.$t('no_record_found'),
      tableHeaders: [
        { name: this.$t('project_name'), key_name: "projectName" },
        { name: this.$t('number_of_members'), key_name: "members" },
        { name: this.$t('estimated_hours2'), key_name: "hoursBooked" },
        { name: this.$t('booked_hours'), key_name: "BookedHrs" },
        { name: this.$t('start_date'), key_name: "Project" },
        { name: this.$t('end_date'), key_name: "Project" },
        { name: this.$t('actions'), key_name: "Project" },
      ],
      // tableHeaders: [
      //   "Project name",
      //   "number of members",
      //   "Estimated Hours",
      //   "Booked Hours",
      //   "Start Date",
      //   "End Date",
      //   "Actions",
      // ],
    };
  },
  components: {
    VsudButton,
    VsudHorzntl,
    Pagination,
    ShowEntries,
    VsudMultiSelect,
  },
  computed: {
    ...mapGetters(["getExistingProjects"]),
    filteredProjects() {
      let This = this;
      let projects = This.getExistingProjects;
      if (This.selectedProjects.length > 0) {

        projects = projects.filter((project) => {
          return This.selectedProjects.includes(project.projectName);
        });
      }
      This.listingData = projects;
      return projects.sort((x, y) => (x.projectName < y.projectName ? -1 : 1));
    },
  },
  mounted() {
    this.fetchData();

    this.setHomeHeader("Home");
    this.setHomeHref("/dashboard");

    this.getExistingProjectAction();
    // console.log("mounted", this.getExistingProjects);
    axios
      .get(`${process.env.VUE_APP_BASE_URL}/timesheet/admin/project`)
      .then((res) => {
        if (res.data) {
          if (res.data && res.data.payload && res.data.payload.timesheet) {
            this.timesheet = res.data.payload.timesheet;
            // console.log(this.timesheet);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this.query.page = parseInt(localStorage.currentPage);
  },
  created() {
    var _isUserLoggedIn = localStorage.isUserLoggedIn;
    if (_isUserLoggedIn === undefined) {
      localStorage.isUserLoggedIn = "no";
      _isUserLoggedIn = localStorage.isUserLoggedIn;
    }
    if (_isUserLoggedIn === "no") {
      this.$router.push({
        path: `/sign-in`,
      });
      return;
    }

  },

  watch: {
    selectedProjects(_new, _old) {
      console.log("_old", _old.length);
      console.log("_new", _new.length);
      if (_new.length !== 0) this.noResultsTextProjects = " ";
      else this.noResultsTextProjects = this.$t('no_record_found');
    },
  },

  methods: {
    ...mapMutations([
      "findProjectById",
      "setProjects",
      "setHomeHeader",
      "setHomeHref",
    ]),
    ...mapActions(["getExistingProjectAction", "projectDelete"]),

    handleMultiSelectProject(payload) {
      const multiselect = JSON.parse(payload);
      this.selectedProjects = multiselect;
      let This = this;
      let projects = This.getExistingProjects;
      if (This.selectedProjects.length > 0) {
        console.log(This.selectedProjects.length, This.selectedProjects.length);
        projects = projects.filter((project) => {
          return This.selectedProjects.includes(project.projectName);
        });
      }
      This.listingData = projects;
    },
    selectRange(e) {
      const { value } = e.target;
      this.query.limit = parseInt(value);
    },

    goToResources(id, projectName) {
      this.$store.state.projectNameResource = projectName;
      this.$router.push({
        path: `/projects/resources/${id}`,
      });
    },

    clearOptions(event) {
      console.log("event", event);
      this.selectedUsers = [];

    },

    clearProjects(event) {
      console.log("event", event);
      this.selectedProjects = [];
      let This = this;
      let projects = This.getExistingProjects;
      This.listingData = projects.sort((x, y) =>
        x.projectName.toLowerCase() < y.projectName.toLowerCase() ? -1 : 1
      );
    },

    switchDate(current) {
      return convertIST(dateFromIso(current));
    },
    updatePage(page) {
      localStorage.currentPage = page;
      localStorage.currentEmpPage = 0;
      this.query = { ...this.query, page };
    },
    fetchData() {
      axios.get(`${process.env.VUE_APP_BASE_URL}/project/true`, { withCredentials: true, })
        .then((res) => {
          if (res.data) {
            if (res.data && res.data.payload && res.data.payload.projects) {
              this.listingData = res.data.payload.projects.sort((x, y) => x.projectName.toLowerCase() < y.projectName.toLowerCase() ? -1 : 1);
              this.timesheets = res.data.payload.projects;
              this.totalPageCount = res.data.payload.projects.length;
              localStorage.currentEmpPage = 0;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onDelete(id) {
      this.toDelete = id;
    },
    confirmDelete() {
      axios
        .delete(`${process.env.VUE_APP_BASE_URL}/project/` + this.toDelete, {
          withCredentials: true,
        })
        .then(() => {
          this.fetchData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goToNewSheet() {
      this.setProjects(this.getExistingProjects);
      this.$router.push({
        path: `/projects/new`,
      });
    },
    goToEditSheet(id) {
      this.$router.push({
        path: `/projects/edit/${id}`,
      });

      this.getExistingProjects.forEach((project) => {
        if (id === project._id) {
          this.findProjectById(project);
          return;
        }
      });
    },

    async copyProject(id) {
      this.$router.push({
        path: `/projects/copy/${id}`,
      });

      this.getExistingProjects.forEach((project) => {
        if (id === project._id) {
          this.findProjectById(project);
          return;
        }
      });
    },

    sortList(sortBy) {
      let This = this;
      console.log(sortBy);
      if (sortBy == "projectName") {
        if (This.projectASC) {
          This.listingData = This.listingData.sort((x, y) =>
            x.projectName.toLowerCase() > y.projectName.toLowerCase() ? -1 : 1
          );
          This.projectASC = false;
        } else {
          This.listingData = This.listingData.sort((x, y) =>
            x.projectName.toLowerCase() < y.projectName.toLowerCase() ? -1 : 1
          );
          This.projectASC = true;
        }
      }
      if (sortBy == "hoursBooked") {
        if (This.hoursBookedASC) {
          This.listingData = This.listingData.sort((x, y) =>
            x.hoursBooked > y.hoursBooked ? -1 : 1
          );
          This.hoursBookedASC = false;
        } else {
          This.listingData = This.listingData.sort((x, y) =>
            x.hoursBooked < y.hoursBooked ? -1 : 1
          );
          This.hoursBookedASC = true;
        }
      }
      if (sortBy == "members") {
        if (This.membersASC) {
          This.listingData = This.listingData.sort((x, y) =>
            x.users.length > y.users.length ? -1 : 1
          );
          This.membersASC = false;
        } else {
          This.listingData = This.listingData.sort((x, y) =>
            x.users.length < y.users.length ? -1 : 1
          );
          This.membersASC = true;
        }
      }
      // if (sortBy == "BookedHrs") {
      //   if (This.BookedHrsASC) {
      //     This.listingData = This.listingData.sort((x, y) =>
      //       // x.users.length > y.users.length ? -1 : 1
      //       This.listingData.find((i) => i._id && i._id === x.projectName)
      //         ? This.listingData.find((i) => i._id === x.projectName).allHours
      //         : 0 >
      //           This.listingData.find((i) => i._id && i._id === y.projectName)
      //         ? This.listingData.find((i) => i._id === y.projectName).allHours
      //         : 0
      //         ? -1
      //         : 1
      //     );
      //     This.BookedHrsASC = false;
      //   } else {
      //     This.listingData = This.listingData.sort((x, y) =>
      //       x.users.length < y.users.length ? -1 : 1
      //     );
      //     This.BookedHrsASC = true;
      //   }
      // }
    },
    sortIcon(column) {
      if (column !== this.sortColumn) {
        return 'fas fa-sort'
      }
      return this.sortDirection === 'asc' ? 'fas fa-sort-up' : 'fas fa-sort-down'
    },
  },
};
</script>
<style scoped>
.table-row {
  cursor: pointer;
}

.multiselect {
  height: 44px;
  border-radius: 4px;
  display: table;
}

.multi_select_customize {
  --ms-ring-color: #ff814fff;
}

.sorting {
  cursor: pointer;
}

.sort_icon {
  padding: 5px;
}
</style>

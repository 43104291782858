<template>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row overflow-auto">
            <div class="col-md-12 d-flex flex-column">
              <div class="mt-2 card bg-white card-plain">
                <div class="card-header pb-0">
                  <vsud-horzntl :header="$t('edit_users')" v-if="this.$route.params.id" />
                  <vsud-horzntl :header="$t('new_users')" v-else />
                </div>
                <div class="card-body" style="height: 80vh">
                  <div class="row">
                    <div class="col-md-6">
                      <vsud-input
                        type="text"
                        name="firstName"
                        :placeholder="$t('first_name')"
                        :value="getUsers.firstName"
                        :isRequired="true"
                        @change-input="handleNewUser"
                        >{{ $t('first_name') }}</vsud-input
                      >
                    </div>
                    <div class="col-md-6">
                      <vsud-input
                        type="text"
                        :placeholder="$t('last_name')"
                        name="lastName"
                        :value="getUsers.lastName"
                        @change-input="handleNewUser"
                        :isRequired="true"
                        >{{ $t('last_name') }}</vsud-input
                      >
                    </div>
                  </div>
                  <vsud-input
                    type="email"
                    name="username"
                    :placeholder="$t('email')"
                    :value="getUsers.username"
                    @change-input="handleNewUser"
                    :isRequired="true"
                    v-if="this.$store.state.origin!=this.$store.state.demoOrigin"
                    >{{ $t('email') }}</vsud-input
                  >
                  <div class="row">
                    <div class="col-md-6">
                      <vsud-input
                        type="password"
                        name="password"
                        :placeholder="$t('password')"
                        :value="getUsers.password"
                        @change-input="handleNewUser"
                        :isRequired="true"
                        v-if="this.$store.state.origin!=this.$store.state.demoOrigin"
                        >{{ $t('password') }}</vsud-input
                      >
                    </div>
                    <div class="col-md-6">
                      <vsud-input
                        type="password"
                        name="confirmPassword"
                        :error="error"
                        :value="confirmPassword"
                        @change-input="handleNewUser"
                        :placeholder="$t('confirm_password')"
                        :isRequired="true"
v-if="this.$store.state.origin!=this.$store.state.demoOrigin"
                        >{{ $t('confirm_password') }}</vsud-input
                  >
                    </div>
                  </div>

                  <vsud-input
                    type="text"
                    name="phoneNumber"
                    :placeholder="$t('phone_number')"
                    :value="getUsers.phoneNumber"
                    @change-input="handleNewUser"
                    :isRequired="true"
                    >{{ $t('phone_number') }}</vsud-input
                  >
                  <vsud-input
                    type="text"
                    name="department"
                    :placeholder="$t('department')"
                    :value="getUsers.department"
                    :isRequired="true"
                    @change-input="handleNewUser"
                    >{{ $t('department') }}</vsud-input
                  >
                  <vsud-date-picker
                    :startDate="new Date(getUsers?.dateOfBirth)"
                    :endDate="null"
                    @date-range="handleDate"
                    >{{ $t('date_of_birth') }}</vsud-date-picker
                  >
                  <!-- <label>Projects</label>
                  <div class="form-group">
                    <div>
                      <select
                        v-model="selectedProjects"
                        class="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        multiple
                      >
                        <option
                          :value="{
                            projectId: project._id,
                            projectName: project.projectName,
                          }"
                          v-for="project in projects"
                          :key="project._id"
                        >
                          {{ project.projectName }}
                        </option>
                      </select>
                    </div>
                  </div> -->
                  
<div class="row d-flex align-items-center mt-4">
                      <div class="col-2 pe-0" style="width: 125px">
                        <label class="my-2">{{ $t('user_visibility') }}</label>
                      </div>
                      <div class="col-1 ps-0">
                        <div class="form-check form-switch">
                          <input
                            :checked = "getUsers.status"
                            :value="getUsers.status"
                            name="status"
                            @change="handleSetStatus"
                            type="checkbox"
                            role="switch"
                            class="form-check-input ps-0"
                          />
                        </div>
                      </div>
                    </div>


                   <div class="text-start">
                    <vsud-button
                      v-on:click="handleSave"
                      class="my-4 mb-2 rounded-1"
                      variant="gradient"
                      color="primary"
                      :style="{ width: '380px' }"
                    >
                      <div v-if="this.$route.params.id">{{ $t('save') }}</div>
                      <div v-else>{{ $t('save') }}</div>
                    </vsud-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";
import VsudDatePicker from "../components/VsudDatePicker.vue";
import VsudInput from "../components/VsudInput.vue";
import VsudHorzntl from "../components/VsudHorzntl.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations, mapGetters, mapActions } from "vuex";
import axios from "axios";
import { useToast } from "vue-toastification";
import { convertIST, dateFromIso } from "../utils/helper";
// import PreviewBoard from "../examples/previewBoard.vue";

export default {
  name: "NewUser",
  components: {
    VsudButton,
    VsudDatePicker,
    VsudInput,
    // PreviewBoard,
    VsudHorzntl,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      projects: [],
      selectedProjects: [],
      error: false,
    };
  },
  computed: {
    ...mapGetters(["getUsers"]),
  },
  mounted() {

    this.fetchData();
    if (this.$route.params.id){
      this.findExistingUsersById(this.$route.params.id)
    }
    
    this.setHomeHeader("Users");
    this.setHomeHref("/users");
  },
  created() {
    var _isUserLoggedIn = localStorage.isUserLoggedIn
    if(_isUserLoggedIn === undefined)
    {
      localStorage.isUserLoggedIn = "no"
      _isUserLoggedIn = localStorage.isUserLoggedIn
    }
    if(_isUserLoggedIn === "no"){
      this.$router.push({
        path: `/sign-in`,
      })
      return
    }

    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    body.classList.add("bg-gray-100");
  },
  unmounted() {
    this.resetUser();
  },
  methods: {
    ...mapMutations([
      "toggleHideConfig",
      "handleNewUser",
      "handleDate",
      "handleSetStatus",
      "resetUser",
      "setHomeHeader",
      'setHomeHref',
      "findExistingUsersById",

    ]),
    ...mapActions(["handleSubmit", "handleEditUser","getExistingUsersAction"]),
    switchDate(current) {
      return convertIST(dateFromIso(current));
    },
    checkPassword(event) {
      this.confirmPassword = event.target.value
    },
    async handleSave() {
      const toast = useToast();
      console.log(this.$route.params.id)
      if (this.$route.params.id) {
        const res = await this.handleEditUser(this.$route.params.id);
        if (res) {
          toast.success(this.$t('successfully_edited_the_user'));
        //  this.$router.push({ path:`/users`});
this.$router.go(-1);
        }

      } else {
        // if(this.firstName === ""){
        //   this.error = true;
        //   toast.warning("First Name cannot be empty");
        // }
        // else if(this.lastName === ""){
        //   this.error = true;
        //   toast.warning("Last Name password cannot be empty");
        // }
        // else if (this.confirmPassword === "") {
        //   this.error = true;
        //   toast.warning("Confirm password cannot be empty");
        // } else if (this.confirmPassword !== this.getUsers.password) {
        //   this.error = true;
        //   return toast.warning("Password do not match!!, please check");
        // } else {
        //   this.error = false;
        // }
        // if (!this.error) {
          const res = await this.handleSubmit();
          if (res) {
            toast.success(this.$t('successfully_created_the_user'));
            this.$router.push({
              path:`/users`
            })
          }
        //}
      }
    },
    fetchData() {
      axios
        .get(`${process.env.VUE_APP_BASE_URL}/project`, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data) {
            if (res.data && res.data.payload && res.data.payload.projects) {
              this.projects = res.data.payload.projects;
              if (this.$route.params.id) {
                axios
                  .get(
                    `${process.env.VUE_APP_BASE_URL}/user/${this.$route.params.id}`,
                    {
                      withCredentials: true,
                    }
                  )
                  .then((res) => {
                    if (res.data) {
                      if (
                        res.data &&
                        res.data.payload &&
                        res.data.payload.user
                      ) {
                        const user = res.data.payload.user;
                        this.firstName = user.firstName;
                        this.lastName = user.lastName;
                        this.email = user.username;
                        this.selectedProjects = user.projects;
                      }
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSignin() {
      if (this.$route.params.id) {
        axios
          .patch(
            `${process.env.VUE_APP_BASE_URL}/user/${this.$route.params.id}`,
            {
              firstName: this.firstName,
              lastName: this.lastName,
              username: this.email,
              password: this.password,
              projects: this.selectedProjects,
            },
            { withCredentials: true }
          )
          .then(() => {
            this.$emit('reset')
          });
      } else {
        axios
          .post(
            `${process.env.VUE_APP_BASE_URL}/user`,
            {
              firstName: this.firstName,
              lastName: this.lastName,
              username: this.email,
              password: this.password,
              projects: this.selectedProjects,
            },
            { withCredentials: true }
          )
          .then(() => {
            this.$emit('reset')
          });
      }
    },
  },
};
</script>
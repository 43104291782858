<template>
  <div>
    <div class="d-flex justify-content-between p-3">
      <p class="team-members">{{ $t('team_members') }}</p>
      <a @click="$router.push('/users')" class="view-all">{{ $t('view_all') }}</a>
    </div>
    <div class="divider"></div>
    <div v-for="(member, index) of TeamMembers.slice(0,5)" :key="member.empId">
      <div class="mt-4 d-flex justify-content-between row px-3 mb-3 align-items-center">
        <div class="d-flex col-9">
          <img src="@/assets/img/team-member.png" class="me-3">
          <div class="d-flex flex-column">
            <span class="name">{{member.empName}}</span>
            <span class="designation">{{member.empDepartment}}</span>
          </div>
        </div>
        <p class="mb-0 mt-2 col-3 px-1">
          <img src="@/assets/img/clock.png">
          <span class="ms-1 hours">{{member.totalHours}}{{ $t('hrs') }}</span>
        </p>
      </div>
      <div class="divider m-3 mb-0" v-if="index != TeamMembers.length -1"></div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'TeamMembers',
    props: {
      TeamMembers: {
        type: Array,
        required: true
      }
    }
  }
</script>
<style scoped>
.team-members {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #232360;
}

.hours {
  font-size: 13px;
  color: #778399;
}

.designation {
  font-size: 16px;
  line-height: 21px;
  color: #768396;
}

.name {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #232360;
}

.divider {
  height: 1px;
  background: #E8EDF1;
}

.view-all {
  font-size: 16px;
  line-height: 21px;
  color: #FF814F;
  cursor: pointer;
}

img {
  object-fit: contain;
}
</style>

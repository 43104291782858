<template>
  <div class="container" v-if="notNewEmplyoee">
    <div
      class="modal fade"
      id="deleteModal"
      tabindex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">{{ $t('confirmation') }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">{{ $t('delete_confirmation') }}</div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
            {{ $t('no') }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              @click="deleteUser(toDelete)"
            >
            {{ $t('yes') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4 px-md-5">
          <div class="card-header pb-0 p-3">
            <div class="row">
              <div class="col-6 d-flex align-items-center fw-bolder">
                {{ $t('inactive_employees') }}
              </div>              
              <vsud-horzntl />
            </div>
          </div>
          <div class="card mb-4">
            <div class="row mb-3 mx-1">
              <div class="col-3">
                <show-entries
                  :value="query.limit"
                  :option="range"
                  @select-range="selectRange"
                />
              </div>
              <div class="col-9 d-flex justify-content-end">
                <div class="col-6 me-3">
                  <vsud-multi-select
                    @set-select="handleMultiSelect"
                    :options="getInActiveUser"
                    :placeholder="$t('search_by_users')"
                    label="fullName"
                    valueProp="_id"
                    :noResults="noResultsText"
                    class="multi_select_customize"
                    @clear-options="clearOptions"
                  />
                </div>
                
              </div>
            </div>
            <div class="card-body px-0 pt-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead style="background: #fafbfc">
                    <tr>
                      <th
                        v-for="header of headers"
                        :key="header"
                        :class="
                          header === 'First Name' || header === 'Last Name'
                            ? `text-uppercase text-secondary text-xs
                          font-weight-bolder
                          text-dark
                          ps-2`
                            : `text-center text-uppercase text-secondary text-xs
                          font-weight-bolder
                          text-dark
                          ps-2`
                        "
                        @click="sortName(header)"
                      >
                        {{ header }}<i v-if="header === 'First Name'" :class="sortIcon(header)" class="sort_icon sorting"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="table-row"
                      v-for="ts in userData.slice(
                        query.limit * query.page,
                        query.limit * query.page + query.limit
                      )"
                      v-bind:key="ts._id"
                    >
                      <td
                        @click="
                          goToUserOverview(ts._id, ts.firstName, ts.lastName)
                        "
                      >
                        <div class="d-flex px-2 py-1">
                          <!-- <div>
                          <vsud-avatar
                          :img="img1"
                          size="sm"
                            border-radius="lg"
                            class="me-3"
                            alt="user1"
                            />
                          </div> -->
                          <div
                            class="d-flex flex-column justify-content-center align-items-start"
                          >
                            <p class="mb-0 fw-bold text-xs text-center">
                              {{ ts.firstName }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td
                        @click="
                          goToUserOverview(ts._id, ts.firstName, ts.lastName)
                        "
                      >
                        <div class="d-flex px-2 py-1">
                          <div
                            class="d-flex flex-column justify-content-center align-content-end"
                          >
                            <p class="mb-0 fw-bold text-xs text-center">
                              {{ ts.lastName }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td
                        @click="
                          goToUserOverview(ts._id, ts.firstName, ts.lastName)
                        "
                      >
                        <div class="d-flex justify-content-center px-2 py-1">
                          <div
                            class="d-flex flex-column justify-content-center"
                          >
                            <p class="mb-0 fw-bold text-xs text-center">
                              {{ ts.username }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td
                        @click="
                          goToUserOverview(ts._id, ts.firstName, ts.lastName)
                        "
                      >
                        <div class="d-flex justify-content-center px-2 py-1">
                          <div
                            class="d-flex flex-column justify-content-center"
                          >
                            <p class="mb-0 fw-bold text-xs text-center">
                              {{ ts.department }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td
                        @click="
                          goToUserOverview(ts._id, ts.firstName, ts.lastName)
                        "
                      >
                        <div class="d-flex justify-content-center px-2 py-1">
                          <div
                            class="d-flex flex-column justify-content-center"
                          >
                            <p class="mb-0 fw-bold text-xs">
                              {{
                                timesheet.find((i) => i._id === ts._id)
                                  ? timesheet.find((i) => i._id === ts._id)
                                      .allHours
                                  : 0
                              }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td
                        class="text-center d-flex justify-content-center align-content-between"
                      >
                        <div class="d-flex flex-row justify-content-center">
                          <a
                            class="btn btn-link text-dark px-3 mb-0"
                            @click="edit(ts._id)"
                          >
                            <i
                              class="fas fa-pencil-alt text-dark me-2"
                              aria-hidden="true"
                            ></i>
                          </a>
                          <a
                            class="btn btn-link text-danger text-gradient px-3 mb-0"
                            data-bs-toggle="modal"
                            data-bs-target="#deleteModal"
                            @click="onDelete(ts._id)"
v-if="this.$store.state.origin!=this.$store.state.demoOrigin"
                          >
                            <i
                              class="far fa-trash-alt me-2"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="pt-3 rounded-2" style="background-color: #fafbfc">
                  <pagination
                    :totalCount="filteredUsers.length"
                    :skip=query.page
                    :limit="query.limit"
                    @update-page="updatePage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div v-else>
      <new-user @reset="resetPage"/>
  </div> -->
</template>

<script>
// import VsudButton from "@/components/VsudButton.vue";
import VsudHorzntl from "../components/VsudHorzntl.vue";
import ShowEntries from "./components/ShowEntries.vue";
import Pagination from "./components/Pagination.vue";
// import NewUser from "../views/NewUser.vue"
import axios from "axios";
import { ref } from "vue";
import VsudMultiSelect from "../components/VsudMultiSelect.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

import "@vuepic/vue-datepicker/dist/main.css";
import { useToast } from "vue-toastification";

export default {
  name: "timesheet",
  data() {
    return {
      projectASC: true,
      userData: [],
      timesheet: [],
      query: {
        limit: 15,
        page: localStorage.currentEmpPage?parseInt(localStorage.currentEmpPage):0,
      },
      selectedUsers: [],
      noResultsText: "No Results Found",
      toDelete: "",
      headers: [
        this.$t('first_name'),
        this.$t('last_name'),
        this.$t('email'),
        this.$t('department'),
        this.$t('hours_booked'),
        this.$t('actions'),
      ],
      notNewEmplyoee: true,
    };
  },
  computed: {

    ...mapGetters(["getInActiveUser"]),
    currentWeek() {
      const currentDate = new Date();
      const currentDay = currentDate.getDay();
      const sunDiff = currentDate.getDate() - currentDay + 1;
      const satDiff = currentDate.getDate() + 6 - currentDay + 1;
      return [
        new Date(new Date(currentDate.setDate(sunDiff)).setHours(0, 0, 0, 0)),
        new Date(new Date(currentDate.setDate(satDiff)).setHours(0, 0, 0, 0)),
      ];
    },

    filteredUsers() {

      let This = this;
      let employees = [...this.getInActiveUser];
      if (this.selectedUsers.length > 0) {
        employees = employees.filter((emp) => {
          return this.selectedUsers.includes(emp._id);
        });
      }
      This.userData = employees.sort((x, y) =>
        x.firstName < y.firstName ? -1 : 1
      );
      return employees.sort((x, y) => (x.firstName < y.firstName ? -1 : 1));
    },

  },
  components: {
//    VsudButton,
    VsudHorzntl,
    Pagination,
    ShowEntries,
    VsudMultiSelect,
    // NewUser,
  },
  created() {
    var _isUserLoggedIn = localStorage.isUserLoggedIn;
    if (_isUserLoggedIn === undefined) {
      localStorage.isUserLoggedIn = "no";
      _isUserLoggedIn = localStorage.isUserLoggedIn;
    }
    if (_isUserLoggedIn === "no") {
      this.$router.push({
        path: `/sign-in`,
      });
    }
  },
  mounted() {
    this.getInActiveUsers();
    this.date = Array(2);
    this.date[0] = this.currentWeek[0];
    this.date[1] = this.currentWeek[1];

    //         const API = axios.create({ baseURL: "http://localhost:4040",
    // headers: {
    //   Authorization: "Bearer 411|yK3VkktZDBXu5BkQVrc15p9rHZWVQacmsZ5zHSm"
    // }});

    axios
      .get(
        `${
          process.env.VUE_APP_BASE_URL
        }/timesheet/admin/user/${this.date[0].getDate()}-${
          this.date[0].getMonth() + 1
        }-${this.date[0].getFullYear()}`
      )
      .then((res) => {
        if (res.data) {
          if (res.data && res.data.payload && res.data.payload.timesheet) {
            this.timesheet = res.data.payload.timesheet;
            console.log("osijdklsdksdl", this.timesheet);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // this.setHomeHeader("Home");
    // this.setHomeHref("/dashboard");
  },
  setup() {
    const date = ref();
    return {
      date,
    };
  },
  watch: {
    selectedUsers(_new, _old) {
      console.log("_old", _old.length);
      console.log("_new", _new.length);
      if (_new.length !== 0) this.noResultsText = "";
      else this.noResultsText = this.$t('no_record_found');
    },
  },
  methods: {
    fetchData() {
      this.getInActiveUsers();

      axios
        .get(
          `${
            process.env.VUE_APP_BASE_URL
          }/timesheet/admin/user/${this.date[0].getDate()}-${
            this.date[0].getMonth() + 1
          }-${this.date[0].getFullYear()}`
        )
        .then((res) => {
          if (res.data) {
            if (res.data && res.data.payload && res.data.payload.timesheet) {
              this.timesheet = res.data.payload.timesheet;
              console.log(this.timesheet, "hehe");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    resetPage() {
      this.getExistingUsersAction();
      this.notNewEmplyoee = true;
    },
    ...mapMutations(["findExistingUsersById", "resetUser","findInactiveUsersById"]),
    ...mapActions([
      "getExistingUsersAction",
      "deleteUser",
      "getInActiveUsers",
    ]),
    handleMultiSelect(payload) {
      const multiselect = JSON.parse(payload);
      console.log(
        "🚀 ~ file: Tables.vue:357 ~ handleMultiSelect ~ multiselect",
        multiselect
      );
      this.selectedUsers = multiselect;
    },

    clearOptions(event) {
      console.log("event", event);
      this.selectedUsers = [];
    },

    clearProjects(event) {
      console.log("event", event);
      this.selectedProjects = [];
    },

    selectRange(e) {
      const { value } = e.target;
      this.query.limit = parseInt(value);
    },
    updatePage(page) {
      localStorage.currentEmpPage=page;
localStorage.currentPage=0;
      this.query = { ...this.query, page };
    },
    onDelete(id) {
      this.toDelete = id;
    },
    fetchTimesheet() {
      if (this.date && this.date[0]) {
        //             const API = axios.create({ baseURL: "http://localhost:4040",
        // headers: {
        //   Authorization: "Bearer 411|yK3VkktZDBXu5BkQVrc15p9rHZWVQacmsZ5zHSm"
        // }});

        axios
          .get(
            `${
              process.env.VUE_APP_BASE_URL
            }/timesheet/admin/user/${this.date[0].getDate()}-${
              this.date[0].getMonth() + 1
            }-${this.date[0].getFullYear()}`
          )
          .then((res) => {
            if (res.data) {
              if (res.data && res.data.payload && res.data.payload.timesheet) {
                this.timesheet = res.data.payload.timesheet;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    confirmDelete() {
      const toast = useToast();

      //           const API = axios.create({ baseURL: "http://localhost:4040",
      // headers: {
      //   Authorization: "Bearer 411|yK3VkktZDBXu5BkQVrc15p9rHZWVQacmsZ5zHSm"
      // }});

      axios
        .delete(`${process.env.VUE_APP_BASE_URL}/user/` + this.toDelete, {
          withCredentials: true,
        })
        .then(() => {
          toast.success("Record deleted Successfully");
          this.fetchData();
        })
        .catch((error) => {
          toast.error("Error deleting record");
          console.log(error);
        });
    },
    edit(id) {
      this.$router.push({
        path: `/users/${id}`,
      });
      this.findInactiveUsersById(id);
    },
    goToNewSheet() {
      this.$router.push({
        path: `/users/new`,
      });
    },
    goToUserOverview(id, firstName, lastName) {
      let employeeName = `${firstName} ${lastName}`;
      this.$store.state.employeeNameOverview = employeeName;
      this.$router.push({
        path: `/users/overview/${id}`,
      });
    },
    sortName(name) {
      let This = this;
      if (name == "First Name") {
        if (This.projectASC) {
          This.userData = This.userData.sort((x, y) =>
            x.firstName.toLowerCase() > y.firstName.toLowerCase() ? -1 : 1
          );
          This.projectASC = false;
        } else {
          This.userData = This.userData.sort((x, y) =>
            x.firstName.toLowerCase() < y.firstName.toLowerCase() ? -1 : 1
          );
          // This.listingData.sort((x, y) => (x.sortBy < y.sortBy ? -1 : 1));
          This.projectASC = true;
        }
      }
    },
    sortIcon(column) {
      if (column !== this.sortColumn) {
        return "fas fa-sort";
      }
      return this.sortDirection === "asc"
        ? "fas fa-sort-up"
        : "fas fa-sort-down";
    },
  },
};
</script>


<style scoped>
.datepicker__input {
  width: 250px;
}
</style>

<style>
.dp__input {
  height: 44px;
  border-radius: 4px;
  border-block: 3px 3px;
}
.dp__theme_light {
  --dp-primary-color: linear-gradient(310deg, #ff814f 0%, #ff814f 100%);
}
.dp__active_date {
  background: linear-gradient(310deg, #ff814f 0%, #ff814f 100%);
}
.multiselect {
  min-height: 40px;
  border-radius: 4px;
  display: table;
}
.multi_select_customize {
  --ms-ring-color: #ff814f;
}

.table-row {
  cursor: pointer;
}
.sorting {
  cursor: pointer;
}
.sort_icon {
  padding: 5px;
}
</style>